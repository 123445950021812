export class TreeItemDto {
    constructor({ displayName, itemPath, itemId, parent, childrens = [], expanded, order, icon }) {
        this.displayName = displayName;
        this.path = itemPath;
        this.id = itemId;
        this.parent = parent;
        this.childrens = childrens;
        this.canDisposeDragDone = false;
        this.noise = Math.random();
        this.Expanded = false;
        this.order = order;
        this.icon = icon;
this.visible = true;
        childrens &&
            childrens.length > 0 &&
            childrens.forEach((item, index) => {
                item.parent = this;
            });
    }

// Search releated
    clearFilter(node)
    {
        var childs = node.getChildrens();
        for (let i = 0; i < childs.length; i++) {
            const child = childs[i];
            child.visible = true;
            this.clearFilter(child);
        }
    }
     
    static setParentChainAsVisible(node)
    {
        var parent = node.getParent();
        while (true) {
            if (!parent)
                return;
            parent.visible = true;
            parent = parent.getParent();
        } 
    }

    applyFilter(filter)
    {
        if (!filter)
        return;

        var childs = this.getChildrens();
        for (let i = 0; i < childs.length; i++) {
            const child = childs[i];
            if(child.getName().toLowerCase().indexOf(filter.toLowerCase()) >= 0)
            {
                child.visible = true;
                TreeItemDto.setParentChainAsVisible(child);
            }
            else
            {
                child.visible = false;                
            }
            child.applyFilter(filter);
        }
    }
    // Search releated
    print(padd = 0) {
        const padding = ' '.repeat(padd);
        console.log(padding + this.displayName);//+ " " + this.path


        if (this.childrens && this.childrens.length > 0) {
            padd += 5;
            this.childrens.forEach((item, index) => {
                item.print(padd);
            });
        }
    }

    // remove a treeItem with a path
    removeItem(itemPath) {

        var node = this.locateItem(itemPath);
        if (node !== false) {
            node.getParent().removeChild(node);
            return true;
        }
        return false;
    }

    addChildTo(parentPath, newItem) {

        var node = this.locateItem(parentPath);
        if (node !== false) {
            node.addChild(newItem);
            return true;
        }
        return false;
    }




    locateItem(parentPath) {
        if (this.getPath() === parentPath) {
            return this;
        }
        else {
            var children = this.getChildrens();
            if (!children || children.length === 0)
                return false;

            var childrenKeys = Object.keys(children);
            for (const key in childrenKeys) {
                var child = children[key];
                var result = child.locateItem(parentPath);

                if (result instanceof TreeItemDto) {
                    return result;
                }
            }
            return false;
        }
    }




    sortChildren()
    {
        if (this.childrens && this.childrens.length > 1)
        {

            const sortedArray = Object.entries(this.childrens).sort((a, b) => {
                return a[1].order - b[1].order;
            });
            
            var r = [];
            sortedArray.forEach(element => {
                r.push(element[1]);
            });
            
            this.childrens = r;
        }
    }

    addChild(newChild,sort=false) {
        this.childrens.push(newChild);
        newChild.parent = this;
        newChild.path = newChild.getPath();
        this.noise = Math.random();
        if (sort===true)
        {
            this.sortChildren();
        }
    }

    removeChild(child) {
        //child.parent = this;
        const index = this.childrens.indexOf(child);
        if (index >= 0) {
            this.childrens.splice(index, 1);
            child.parent = null;
        }
        this.noise = Math.random();
    }

    getParent() {
        return this.parent;
    }

    getPath() {
        if (this.parent) {
            return this.parent.getPath() + "/" + this.displayName;
        } else {
            return this.path;
        }
    }
    getOrder() {
        return this.order;
    }
    getIcon() {
        return this.icon;
    }
    getName() {
        return this.displayName;
    }

    getChildrens() {
        return this.childrens;
    }

    getSiblings() {
        if (this.parent === null) {
            return null;
        }
        return this.parent.getChildrens();
    }

    // Static Functions

    /**
 * @param {TreeItemDto} items 
 * @param {string} itemPath 
 * @param {string} parentPath 
 */
    static moveItemTo(items, itemPath, parentPath, newOrder=-1, copy = false) {
        var child = items.locateItem(itemPath);
        var parent = items.locateItem(parentPath);

        if (child && parent) {

            if (copy === false) {
                child.getParent().removeChild(child);
            }
            if (newOrder !== -1)
            {
                child.order=newOrder;
            }
            parent.addChild(child,true);
            return true;
        }
        return false;
    }

    static getItemParents(treeItems, itemPath) {
        var child = treeItems.locateItem(itemPath);
        var result = [];

        while (true) {
            var parent = child.getParent();
            if (!parent) {
                break;
            }
            result.push(parent.getPath());
            child = parent;
        }
        return result;
    }

}
