import React, { useEffect, useRef, useState } from "react";
import { Page_dto } from "./Dtos";
import { AppManager } from "./AppManager";
import { PageManager } from "./PageManger";
import { TreeItemDto } from "./DTOs/TreeItemDTO";
 
/**
 * @typedef {Object|null} AppContextType
 * @property {Function} clearSettings
 * @property {Function} getSettings
 * @property {Function} updateAppContext
 * @property {boolean} EditorLoadedFirstTime
 * @property {draggedItem} EditorLoadedFirstTime
 * @property {setDraggedItem} EditorLoadedFirstTime
 * @property {Function} updateTreeItems
 * @property {Function} getTreeItems
 * @property {boolean} isTreeItemsReady
 * @property {Function} setActiveDocumentData
 * @property {Function} getActiveDocumentData
 * @property {Function} setADFirstLoadingDone
 * @property {Function} isADFirstLoadingDone
 */

const AppContext = React.createContext(null);
export const AppContextProvider = AppContext.Provider;
export const MyContextConsumer = AppContext.Consumer;

// export const useAppContext = () => {
//   const contextValue = React.useContext(AppContext);
//   if (!contextValue) {
//     throw new Error("useAppContext must be used within a MyContextProvider");
//   }
//   return contextValue;
// };

const externalStorage = localStorage;

const activePage = new Page_dto({ name: "", });

/**
 * @typedef {Object} AppSettings
 * @property {Page_dto} selectedPage - current active page
 * @property {Array} expandedItems - current active page
 */

export const userDefault = {
  full_name: "",
  root_path: "",
  isLogged: false,
};

/**
 * @type {AppSettings}
 */
const defaultSettings = {
  config: {
    ge: true,
    fe: true,
  },
  language: "en",
  user: userDefault,
  needSave: false,
  selectedPage: activePage,
  expandedItems: [],
  cached: {},
};

function getsettingsFromLocalStorage() {
  var storage = externalStorage.getItem("settings");
  return storage ? JSON.parse(storage) : defaultSettings;
}

function AppProvider({ children }) {

  const [settings, setSettings] = useState(getsettingsFromLocalStorage());
  const [leftMenu, setLeftMenu] = useState(false);
  const [draggedItem, setDraggedItem1] = useState(null);
  const [isTreeItemsReady, setIsTreeItemsReady] = useState(false);
  const useLeftMenu = { leftMenu, setLeftMenu };
  const EditorLoadedFirstTime = useRef(false);
  const EditorRef = useRef(null);

  const [TreeItems, setTreeItems] = useState(null);
  const isActiveDocumentFirstLoadingDone = useRef(true);
  const [fld,setFld] = useState(null);

  const [ADData, setADData] = useState(null);

  useEffect(() => {
    console.log("Run once");

  }, []);

  function getTreeItems(AppContext) {
    //console.log("getting tree items from context");
    setIsTreeItemsReady(false);

    if (TreeItems === null) {
      updateTreeItems(AppContext);
    }
    else {
      setIsTreeItemsReady(true);

    }
    return TreeItems;


  }
  function updateTreeItems(AppContext) {

    var am = new AppManager(AppContext);
    var pm = new PageManager(AppContext);
    am.api_getPagesListInPath(
      "/",
      (apiData) => {
        //console.log("Getting the new tree items from server");
        var fullTreeItems = pm.BuildTreeItemsDtoRecursivly(apiData, new TreeItemDto({ displayName: "/", itemPath: "" }));
        setTreeItems(fullTreeItems);
        setIsTreeItemsReady(true);
      },
      () => {
        alert("Some error while getting the tree");
        setIsTreeItemsReady(false);
      }
    );
  }



  function setDraggedItem(params) {
    if (!params) {
      console.warn("Dragged item is null");
    }
    setDraggedItem1(params)
  }

  const updateAppContext = (newSetting, toDelete) => {
    //console.log("updateAppContext", newSetting);
    setSettings((current) => {
      if (Array.isArray(toDelete)) {
        toDelete.forEach((x) => delete current[x]);
      }
      //console.log("should be ..", { ...current, ...newSetting });
      return { ...current, ...newSetting };
    });
  };

  React.useEffect(() => {
    var a = JSON.stringify(settings);
    externalStorage.setItem("settings", a);
  }, [settings]);

  const clearSettings = () => {
    setSettings((current) => {
      return {};
    });
    externalStorage.removeItem("settings");
  };

  /**
   * @returns {AppSettings}
   */
  function getSettings() {
    return settings;
  }


  function setActiveDocumentData(data) {
    setADData((old) => data);
  }

  function getActiveDocumentData() {
    return ADData;
  }

  // 
  function setADFirstLoadingDone(loadingStatus) {
    setFld(loadingStatus);
    //isActiveDocumentFirstLoadingDone.current = loadingStatus;
  }
  function isADFirstLoadingDone() {
   // return isActiveDocumentFirstLoadingDone.current;
    return fld;
  }

  return <AppContextProvider value={{ clearSettings, getSettings, updateAppContext, useLeftMenu,
    EditorLoadedFirstTime, EditorRef, draggedItem, setDraggedItem, getTreeItems, updateTreeItems, isTreeItemsReady, 
    
    setActiveDocumentData, getActiveDocumentData, setADFirstLoadingDone, isADFirstLoadingDone }}>{children}</AppContextProvider>;
}

export { AppProvider, AppContext };
