import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { AppContext, AppContextType } from "../../utilities/storage";
import { AppManager } from "../../utilities/AppManager";
import { PageBanner_dto } from "../../utilities/Dtos";

export default function PageMainBanner({ src, size = "cover" }) {
  //scalep = 1, offsetX = 0, offsetY = 0
  /**
   * @type {AppContextType} */
  const appContext = React.useContext(AppContext);
  const { getSettings } = React.useContext(AppContext);
  const am = new AppManager(appContext);

//  var p0 = am.getActivePageMetaInfoOrDefault();
  const [hooked, setHooked] = useState(false);
  const [p, setP] = useState({ x: 0, y: 0 });
  const [bg, setBg] = useState(src);
  const [scaleValue, setScaleValue] = useState(100);
  const div = useRef(null);
  function handleMouseDown(event) {
    setHooked(true);
  }

  function handleMouseUp(event) {
    setHooked(false);
    console.log("Save the banner position");
   am.setIsPageNeedSave(true);
    //updateContext();
  }

  function updateContext() {
    var banner = am.getActivePageBannerOrDefault();
    if (p && scaleValue) {
      banner.offsetX = p.x;
      banner.offsetY = p.y;
      banner.zoom = scaleValue;
      am.setActivePageBannerInfo(banner);
    }
  }

  function handleMouseMove(event) {
    var dx = event.movementX;
    var dy = event.movementY;
    // var button = event.left;

    if (hooked) {
      //  console.log("clientX:", event.clientX, "clientY:", event.clientY);
      //  console.log("pageX:", event.pageX, "pageY:", event.pageY);
      //  console.log("screenX:", event.screenX, "screenY:", event.screenY);

      if (dx !== 0 || dy !== 0) {
        //console.log("handle image move");
        setP((p) => {
          return { x: p.x + dx, y: p.y + dy };
        });
      }
    }
  }

  function handleWheel(event) {
    if (hooked) {
      console.log("Handle wheel");
      event.preventDefault();
      event.stopPropagation();

      var s = scaleValue + Math.sign(event.deltaY);
      if (s < 0) s = 1;

      setScaleValue((s) => {
        var val = s + Math.sign(event.deltaY);
        if (val < 0) val = 1;
        console.log("scale is", val);

        return val;
      });
    }
  }

  useEffect(() => {
 
    updateContext();
  }, [scaleValue, p]);

  useEffect(() => {
    if (div && div.current) {
      div.current.addEventListener("wheel", handleWheel);
    }

    var banner = am.getActivePageBannerOrDefault();

    if (p && (p.x !== banner.offsetX || p.y !== banner.offsetY)) {
      setP({ x: banner.offsetX, y: banner.offsetY });
    }
    setScaleValue(banner.zoom);

    return () => {
      if (div && div.current) {
        // console.warn("remove wheel event");
        // eslint-disable-next-line
        div.current.removeEventListener("wheel", handleWheel);
      }
    };
    // eslint-disable-next-line
  }, [appContext]);

  if (p === null) return "";

  return (
    <>
      <div className="banner">
        <div ref={div} onWheel={handleWheel} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove} loading="lazy" className="img-banner" style={{ backgroundImage: "url(" + src + ")", backgroundPositionX: p.x + "px", backgroundPositionY: p.y + "px", backgroundSize: scaleValue + "%" }} />
      </div>
    </>
  );
}
