import { useContext } from "react";
import { AppManager } from "../utilities/AppManager";
import { AppContext } from "../utilities/storage";

import { PageManager } from "../utilities/PageManger";

import { enum_page_type } from "../utilities/enums";
import { AppContextType } from "../utilities/storage";
import { Editor } from "./editor/Editor";

import { MyDocumentType } from "../utilities/IDocument";
import { GalleryEditor } from "./GalleryEditor/GalleryEditor";

export default function RightPane() {

  /** @type AppContextType */
  const appContext = useContext(AppContext);
  var am = new AppManager(appContext);
  const pm = new PageManager(appContext);


  var selectedPage = am.getActivePage();

  var pageType = selectedPage && (selectedPage.meta && selectedPage.meta.type) || MyDocumentType.text.toString();

  if (pageType === MyDocumentType.text.toString() || !pageType || pageType === enum_page_type.rich_text_document) {
    return <Editor />
  }
  else if (pageType === MyDocumentType.gallery.toString() || enum_page_type.image_gallery) {
    var data = appContext.getActiveDocumentData();
    return <GalleryEditor />
  }
  else {
    return <GalleryEditor />
  }
}
