import React from "react";
import { BarLoader, BounceLoader, HashLoader, MoonLoader, PulseLoader } from "react-spinners";


export default function ElevatedCard({ className = "", isBusy = false, busyIndicatorLocation = "bottom", children = {}, ...props }) {
    return (
        <div className={ " elevated-card " + (isBusy && "disabled") + " " + className }>
            {isBusy && (busyIndicatorLocation === "top" || busyIndicatorLocation === "both") && <BarLoader color="var(--primary-color-dark)" speedMultiplier={2} width={"100%"} />}
            {children}
            {isBusy && (busyIndicatorLocation === "bottom" || busyIndicatorLocation === "both") && <BarLoader color="var(--primary-color-dark)" speedMultiplier={2} width={"100%"} />}
        </div>
    );
}
