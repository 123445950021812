import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $setBlocksType } from "@lexical/selection";
import { $createParagraphNode, $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW, ElementNode, createCommand } from "lexical";
import { useLayoutEffect } from "react";

export class BigNoteNode extends ElementNode {
  static myType = "_banner";

  static getType() {
    return BigNoteNode.myType;
  }

  static clone(node) {
    return new BigNoteNode();
  }

  createDOM(config) {
    const element = document.createElement("div");
    element.className = config.theme.bignote;
    return element;
  }

  updateDOM() {
    return false;
  }

  // to delete the whole block
  collapseAtStart(selection) {
    const p = $createParagraphNode();
    const children = this.getChildren();
    children.forEach((c) => p.append(c));
    this.replace(p);
    return true;
  }

  insertNewAfter(selection, restoreSelection) {
    const newBlock = $createParagraphNode();
    newBlock.setDirection(this.getDirection());
    this.insertAfter(newBlock, restoreSelection);
    return newBlock;
  }

  exportJSON() {
    console.log("-----------------");

    return {
      ...super.exportJSON(),
      type: BigNoteNode.getType(),
      version: 1,
    };
  }
  static importJSON(serializedNode) {
    const node = $createBigNoteNode();
    node.setFormat(serializedNode.format);
    node.setIndent(serializedNode.indent);
    node.setDirection(serializedNode.direction);
    return node;
  }
}

export function $createBigNoteNode() {
  return new BigNoteNode();
}

export function $isBigNoteNode(node) {
  return node instanceof BigNoteNode;
}

export const INSERT_BIGNOTE_COMMAND = createCommand("insertBigNote");

export function BigNotePlugin() {
 // const appContext = useContext(AppContext);


  const [editor] = useLexicalComposerContext();
  if (!editor.hasNodes([BigNoteNode])) {
    throw new Error("BigNote Node is not registered with the editor");
  }
  editor.registerCommand(
    INSERT_BIGNOTE_COMMAND,
    () => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, $createBigNoteNode);
      }
      //console.log("xxx");
      return true;
    },
    COMMAND_PRIORITY_LOW
  );

  useLayoutEffect(() => {
    const onKeyDown = (event) => {
    
      // "Ctrl" or "Cmd" + "s"
      if ((event.ctrlKey || event.metaKey) && event.which === 83) {
        
        
      }
    };

    return editor.registerRootListener((rootElement, prevRootElement) => {
      if (prevRootElement !== null) {
       
        
        prevRootElement.removeEventListener("keydown", onKeyDown);
      }
      if (rootElement !== null) {
        rootElement.addEventListener("keydown", onKeyDown);
      }
    });
    // eslint-disable-next-line 
  }, [editor]);

  return null;
}
