import { CLEAR_HISTORY_COMMAND, LexicalEditor } from "lexical";
import { AppManager } from "../AppManager";
import { IDocument, MyDocumentType } from "../IDocument";
import { BaseDocument } from "../IDocument";


export class TextRichDocument extends BaseDocument implements IDocument {

    public editor: LexicalEditor;
    private appContext: any;
    private appManager: any;
 
    get()
    {
        return <div>123</div>
    }

    SaveDocument(fileName: string): boolean {
        throw new Error("Method not implemented.");
    }
    ExportDocument(fileName: string, format: string): boolean {
        throw new Error("Method not implemented.");
    }
    IsDocumentSaved(): boolean {
        throw new Error("Method not implemented.");
    }
    UpdateDocumentMeta(): boolean {
        throw new Error("Method not implemented.");
    }

    constructor(DocumentPath: string, editor: LexicalEditor, appContext: any) {
        super(DocumentPath, MyDocumentType.text);

        this.editor = editor;
        this.appContext = appContext;
        this.appManager = new AppManager(this.appContext);
    }

    private callback_afterEditorLoad(editor: LexicalEditor, am: any) {
        editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
        am.setIsPageNeedSave(false);
    }

    private SetEditorContent(data: string) {
        var state = this.editor.parseEditorState(data);
        this.editor.setEditorState(state);
    }


    OpenDocument(data: any, meta:any, successCallback: (...args: any[]) => unknown, failCallback: (...args: any[]) => unknown): string {

    //    this.SetEditorContent(data);
        
    //    this.callback_afterEditorLoad(this.editor, this.appManager);
        successCallback?.();
        return data;
    }

    private static v1: number = 0;
};

