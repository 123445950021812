import Cookies from "js-cookie";
import { SettingsManager } from "./SettingsManager";
import { language_en, setting_key_language, setting_key_token } from "./consts";

export const app_version = 100;
export const header_platform = "web_kb";
export const allow_http_caching = true;
export const enum_app_language = { en: "en", bm: "bm", cc: "cc" };

class callCache {
    constructor() {
        this.url = "";
    }
}

class CachedCall {
    constructor(until, data) {
        this.until = until;
        this.data = data;
    }
}

export function clearExpiredCachedCalls(appContext, all) {
    if (all === true) {
        appContext.getSettings().cached = {};
        appContext.updateAppContext({ ...appContext.getSettings() });
    }

    var urls = Object.keys(appContext.getSettings().cached);
    const now = new Date();
    var newCached = {};
    for (const url in appContext.getSettings().cached) {
        var item = appContext.getSettings().cached[url];
        var until = new Date(item.until);
        var diff = now - until;
        if (diff < 0) {
            newCached[url] = item;
        }
    }
    appContext.getSettings().cached = newCached;
    appContext.updateAppContext({ ...appContext.getSettings() });
}

function getCallResultFromCache(url, appContext) {
    const now = new Date();

    //TFIXME A BUG POSIIBLE HITTED
    // Wait until we have it again
    if (appContext.getSettings() && appContext.getSettings().cached) {
        var urls = Object.keys(appContext.getSettings().cached);
        if (urls.indexOf(url) >= 0) {
            var item = appContext.getSettings().cached[url];
            var until = new Date(item.until);
            var diff = now - until;
            //console.info("check timing ", diff / (60 * 1000));
            if (diff < 0) {
                return item.data;
            } else {
                return null;
            }
        }
    }
    clearExpiredCachedCalls(appContext);
}

function cacheTheResult(url, result, apiResult, cachingDuration, appContext) {
    const now = new Date();
    now.setMinutes(now.getMinutes() + cachingDuration);
    result.response = apiResult;
    var x = new CachedCall(now, result);
    appContext.getSettings().cached[url] = x;
    appContext && appContext.updateAppContext({ ...appContext.getSettings() });
}

// override this function to get the token
function getToken() {
    return Cookies.get(setting_key_token);
 
}

// override ...
function getLanguage() {
    var sm = new SettingsManager();
    return sm.getSetting(setting_key_language) || language_en;
}

export const apiCall = async (inputUrl, params, method, data, extraHeaders = null, useCache = false, cacheTheCall = false, cachingDuration = 1, unAuthorizedCallback = null) => {

    if (!method) method = "Get";
    if (!data) data = null;

    // Build url from query string
    const urll = new URL("", inputUrl);
    if (params) {
        Object.keys(params).forEach((key) => urll.searchParams.append(key, params[key]));
    }
    const url = urll.href;

    var jwtToken = getToken();
    var enableLog = false;
    enableLog && console.group("api call " + url);

    // Basic Headers
    let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        platform: header_platform,
        version: app_version,
        language: getLanguage(),
    };

    if (extraHeaders && ["Get"].indexOf(method) === -1) {
        headers = { ...headers, ...extraHeaders };
    }

    // Be sure to set the bearer token as last step to prevent override
    if (jwtToken) {
        headers.Authorization = `Bearer ${jwtToken}`;
    }
 
    const requestOptions = {
        method: method,
        headers: headers,
    };

    if (data) {
        requestOptions["body"] = JSON.stringify(data);
    }

    // Caching 
    if (useCache && allow_http_caching) {
        // var r = getCallResultFromCache(url, appContext);
        // if (r) {
        //     enableLog && console.info("return from cache ");
        //     enableLog && console.groupEnd();
        //     return r;
        // }
    }

    enableLog && console.info("do live server call ");
    enableLog && console.groupEnd();


    // Don't do resp.json() unless appllication/json is received
    var response = null;
    var responseObj = { success: null, data: null };
    responseObj.success = false;
    await fetch(url, requestOptions)
        .then(async (resp) => {
            if (resp.status === 200) {
                responseObj.success = true;
                response = await resp.json();
                if (allow_http_caching && cacheTheCall && method.toLowerCase() === "get") {
                    //cacheTheResult(url, result, apiResult, cachingDuration, appContext);
                }
            } else if (resp.status === 401) {
                response = await resp.json();
                // logout if not authorized (need to login again)
                if (unAuthorizedCallback) {
                    //unAuthorizedCallback(appContext);
                }
            } else {
                response = await resp.json();
                throw new Error(resp.statusText);
            }
        })
        .catch((error) => {
            console.error(`[API ERROR (${url})] ` + error);
        })
        .finally((f) => { });

    responseObj.data = response;
    return responseObj;
};

 