import Cookies from "js-cookie";
import { SettingsManager } from "./SettingsManager";
import { setting_key_bearer_token, setting_key_is_user_logged } from "./consts";
import { AppContextType } from "./storage";




export class UserManager {

    private appContext: AppContextType | null;
    private settingManager: SettingsManager;

    constructor(appContext: AppContextType | null, settingManager: SettingsManager) {
        this.appContext = appContext;
        this.settingManager = settingManager;
    }

    is_user_logged_in() {
        var result = false;
        var isLogged = this.settingManager.getSetting(setting_key_is_user_logged);
        if (!isLogged)
            result = false;

        result = (isLogged && isLogged.toLowerCase() === 'true') || false;

        console.log("check is logged = ", result);
        return result;
    }

    updateUserLoginData(isLogged: boolean) {
        this.settingManager.setSetting(setting_key_is_user_logged, isLogged);
    }

    getAccessToken() {
        return Cookies.get("token");
    }

}