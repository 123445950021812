import { $getRoot } from "lexical";
import { AppManager } from "./AppManager";

import { TreeItemDto } from "./DTOs/TreeItemDTO";

import { url_delete_file, url_new_file } from "./consts";
import { enum_page_save_status, enum_page_type } from "./enums";
import { apiCall } from "./fetchApi";
import { AppContextType } from "./storage";
import { PageMeta_dto } from "./Dtos";
import { DocumentManager } from "./DocumentManager";
import { MyDocumentType } from "./IDocument";


export class PageManager {
  /**
   * @param {AppContextType} appContext
   */
  constructor(appContext) {
    this.appContext = appContext;
  }

  async handleSearchPage() { }

  async handleCreateNewPage(appContext, editor, dialogBox, pagePath, setIsBusy, successCallback, failCallback) {
    console.info("Handle Create new page");

    // Ask for page save if need first
    await this.HandleAskForPageSaveIfNeedAndCallback(dialogBox.showModal, appContext, editor, async () => { setIsBusy && setIsBusy(true); await HandleStartCreatingNewPage(appContext, pagePath, editor, successCallback, failCallback); }, () => { alert("Failed to save page"); });

    async function HandleStartCreatingNewPage(appContext, pagePath, editor, successCallback, failCallback) {
      var pageName = prompt("page name", "new page " + Math.round(Math.random() * 100));
      if (!pageName) { successCallback && successCallback(null); return; }

      var call = await apiCall(url_new_file, {}, "Post", { pageName, pagePath: pagePath });
      if (call.success === true) {
        if (call.data === "done") {
          successCallback && successCallback(pageName);
        }
      } else {
        failCallback && failCallback();
      }
    }
  }

  async handleDeletePage(appContext, dialogBox, pagePath, editor, setIsBusy, successCallbakc, failCallback) {
    console.log("handle delete page");

    // handle page save
    await this.HandleAskForPageSaveIfNeedAndCallback(dialogBox.showModal, appContext, editor, async () => { await HandleStartDeletingPage(appContext, pagePath, setIsBusy, successCallbakc, failCallback); }, () => { alert("Failed to save page"); });

    async function HandleStartDeletingPage(appContext, pagePath, setIsBusy, successCallback) {
      var confirmDelete = window.confirm("Delete " + pagePath + "?");

      if (confirmDelete === true) {
        var call = await apiCall(url_delete_file, {}, "Post", { pagePath });
        if (call.success === true) {
          if (call.data === "done") {
            // make the name coming from back end with "done" object
            successCallback && successCallback();

            // Set focus to sibling or parent
            setIsBusy && setIsBusy(false);
          }
        } else {
          alert("Some error");
          setIsBusy && setIsBusy(false);
        }
      }
    }
  }

  // Handle asking to save page or not and return next or fail callback
  async HandleAskForPageSaveIfNeedAndCallback(showModal, appContext, editor, nextCallback, failedCallback) {
    const am = new AppManager(appContext);
    const currentOpenPage = am.getActivePage().path;
    var status = enum_page_save_status.unknown;

    var onPositiveClick = async () => { await this.HandleCurrentPageSave(appContext, editor, async () => { status = enum_page_save_status.saved; am.setIsPageNeedSave(false); nextCallback && (await nextCallback(status)); }, async () => { failedCallback && (await failedCallback()); }); };

    var onNegativeClick = async () => {
      status = enum_page_save_status.notsaved;
      nextCallback && (await nextCallback(status));
    };

    if (am.isCurrentPageNeedSave() === true) {
      showModal({ title: "title", body: "Save " + currentOpenPage + "?", canDismiss: true, positiveCallback: onPositiveClick, negativeCallback: onNegativeClick, cancelCallback: () => { } });
    } else {
      status = enum_page_save_status.nochange;
      nextCallback && (await nextCallback(status));
    }
    return null;
  }

  /**
   * A function GET Current Page, Current Editor data, and save the page
   * @param {Function} successCallback - The second number.
   * @param {Function} failCallback - The second number.
   */
  async HandleCurrentPageSave(appContext, editor, successCallback, failCallback) {
    var state = editor.getEditorState();
    const text = state.read(() => $getRoot().getTextContent());
    const am = new AppManager(appContext);
    const meta = am.getActiveMetaInfoOrDefault();
    const path = am.getActivePage().path;
    //const toSave = { data: state, text, meta };

    await am.api_savePage(path, state, text, meta, MyDocumentType.text.toString(), successCallback, failCallback);
  }

  // Handle : Ask for save if required, and load the other page into editor
  // Need to handle yes, no, cancel
  async HandleLoadOtherPageIntoEditor(showModal, appContext, pageName, pagePath, editor, successCallback, failCallback) {
    const am = new AppManager(appContext);
    const currentOpenPage = am.getActivePage().name;

    this.HandleAskForPageSaveIfNeedAndCallback(
      showModal,
      appContext,
      editor,
      async () => {
        await this.ChangeActivePageAndGetPageDataAndUpdateEditor(appContext, pageName, pagePath, editor, successCallback, failCallback);
      },
      () => {
        alert("error while saving " + currentOpenPage);
      }
    );
  }

  /**
   * A function Load Page data and meta and update active page
   * @param {TreeItemDto} treeItemDto - The second number.
   * @param {AppContextType} appContext - The second number.
   */
  async ChangeActivePageAndGetPageDataAndUpdateEditor(appContext, pageName, pagePath, editor, successCallback, failCallback) {
    const am = new AppManager(appContext);
    //  const pagePath = treeItemDto.getPath();
    // const pageName = treeItemDto.displayName;

    await am.api_getPageData(
      pagePath,
      true,
      (apiData) => {
        // Check that api returned useful info
        if (apiData && Object.keys(apiData).length > 0) {
          // Change active page
          am.setActivePage(pageName, pagePath);

          var documentType = enum_page_type.rich_text_document;
          if (apiData.meta) {
            var meta = JSON.parse(apiData.meta);
            meta = Object.assign(new PageMeta_dto({}), meta);
            documentType = meta.type;

            // Update active page meta
            am.setActivePageMetaInfo(JSON.parse(apiData.meta));
          }



          var handler = DocumentManager.GetDocumentHandler(documentType, pagePath, editor, appContext);
          var docData = handler.OpenDocument(apiData.data, apiData.meta, successCallback, null);


          appContext.setActiveDocumentData(docData);
          appContext.setADFirstLoadingDone(false);
          console.log("*** Set First Time Load Ready to false");



          //successCallback && successCallback();
        }
      },
      // Handle api error
      () => {
        const error = "error while reading " + pagePath;
        if (!failCallback) alert(error);

        failCallback && failCallback(error);
      }
    );
  }

  /**
   * @param {TreeItemDto} rootNode - The second number.
   * @returns {(null|TreeItemDto|Array)} Sum of a and b or an array that contains a, b and the sum of a and b.
   */
  BuildTreeItemsDtoRecursivly(data, rootNode) {
    if (typeof data !== "object" || data === null) {
      return null;
    }

    var pageNames = Object.keys(data);
    pageNames.forEach((pageName) => {
      var pagePath = rootNode.getPath() + "/" + pageName;

      var newTreeItem = new TreeItemDto({ displayName: pageName, itemPath: pagePath, order: data[pageName].order, icon: data[pageName].icon });

      rootNode.addChild(newTreeItem);
      var newItems = this.BuildTreeItemsDtoRecursivly(data[pageName]['subdirs'], newTreeItem);
      if (newItems != null && newItems.length > 0) {
        rootNode.addChild(newItems);
      }
    });
      // sort if possible 
      rootNode.sortChildren();

    return rootNode;
  }
}
