import { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
 
import LoginComponent from "../components/Login";
import { SettingsManager } from "../utilities/SettingsManager";
import { UserManager } from "../utilities/UsersManager";
import { AppContext, AppContextType } from "../utilities/storage";
import Cookies from "js-cookie";
import { setting_key_token } from "../utilities/consts";
 


export default function LoginPage() {
    var context = useContext(AppContext);
     
    var history = useHistory();
    
    var sm = new SettingsManager();
    var um = new UserManager(context,sm);
    var token = Cookies.get(setting_key_token);
    console.log("the cookie is", token);
    if (um.is_user_logged_in() === true && (token !== null || token !==undefined) ) {
        history.push("/");
        return <>Redirecting</>;
    } else {
        return (
            <Fragment>
                <LoginComponent />
            </Fragment>
        );
    }
}
