import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection";
import { mergeRegister } from "@lexical/utils";
import { $createNodeSelection, $getNodeByKey, $getSelection, $isNodeSelection, $setSelection, CLICK_COMMAND, COMMAND_PRIORITY_LOW, KEY_BACKSPACE_COMMAND, KEY_DELETE_COMMAND } from "lexical";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { $isYouTubeNode } from "./youtube_node";

export function YouTubeComponent({ classname, nodeKey, videoID }) {
  const editor = useLexicalComposerContext()[0];

  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey);
  // eslint-disable-next-line
  const [isEditable, setIsEditable] = useState(true);

  const videoRef = React.useRef(null);

  const onDelete = useCallback(
    function onDelete(event) {
      if (isSelected && $isNodeSelection($getSelection())) {
        var node = $getNodeByKey(nodeKey);
        if ($isYouTubeNode(node)) {
          event.preventDefault();
          console.warn("delete node", nodeKey);
          var sibling = node.getPreviousSibling();
          node.remove();
          clearSelection();
          setSelected(false);
          editor.update(() => {
            // sselect previous element if any
            if (sibling !== null) {
              const nodeSelection = $createNodeSelection();
              nodeSelection.add(sibling.getKey());
              $setSelection(nodeSelection);
            }
          });

          return true;
        }
      }
      return false;
    },
    // eslint-disable-next-line
    [nodeKey, isSelected, setSelected]
  );

  useEffect(() => {
    // eslint-disable-next-line
    let isMounted = true;
    const unregister = mergeRegister(
      editor.registerEditableListener((status) => {
        console.warn("Youtube Editor status is:", status);
        setIsEditable(status);
      }),
      editor.registerCommand(
        CLICK_COMMAND,
        (payload) => {
          const event = payload;
          if (event.target === videoRef.current) {
            clearSelection();
            setSelected(true);
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),

      editor.registerCommand(KEY_DELETE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, onDelete, COMMAND_PRIORITY_LOW)
      // editor.registerCommand(KEY_ENTER_COMMAND, onEnter, COMMAND_PRIORITY_LOW),
      // editor.registerCommand(PASTE_COMMAND, onPaste, COMMAND_PRIORITY_LOW)
      // editor.registerCommand(KEY_ESCAPE_COMMAND, onEscape, COMMAND_PRIORITY_LOW)
    );
    return () => {
      isMounted = false;
      unregister();
    };
  }, [editor, clearSelection, setSelected, isSelected, onDelete]);
  //console.log("render video componenet with id:", nodeKey);
  return (
    <div className={"video-wrap " + classname + " " + (isSelected === true ? "f1" : "f2")} ref={videoRef}>
      <span>{`https://www.youtube.com/embed/${videoID}`}</span>
      {/* <iframe width="320" height="220" src={`https://www.youtube.com/embed/${videoID}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} title="YouTube video" /> */}
    </div>
  );
}
