import { enum_page_type } from "./enums";


// Page
// 1) content -> page.json
// 2) Meta (Banner, type, createdDate, ...) -> meta.json

export class PageBanner_dto {
  constructor(background, icon, offsetX, offsetY, zoom) {
    this.background = background || "";
    this.icon = icon || "";
    this.offsetX = offsetX || 0;
    this.offsetY = offsetY || 0;
    this.zoom = zoom || 1;
  }
}

export class PageMeta_dto {
  constructor({ banner, type }) {
    this.banner = banner || new PageBanner_dto({});
    this.type = type || enum_page_type.rich_text_document;
  }
}

export class Page_dto {
  /**
   *
   * @param {PageBanner_dto} banner
   */
  constructor({ name, path, content, /** @type {PageMeta_dto} */ meta, needSave, type = enum_page_type.rich_text_document }) {
    this._name = name;
    this._path = path;
    this._content = content;
    this._type = type;
    this._meta = meta || new PageMeta_dto({ banner: new PageBanner_dto("https://w0.peakpx.com/wallpaper/1009/531/HD-wallpaper-plasma-abstract-color-colour.jpg", null, 0, 0, 1), type: enum_page_type.rich_text_document });
    this._needSave = needSave || true;
  }

  update({ name, path, content, needSave }) {
    if (name) this._name = name;
    if (path) this._path = path;
    if (content) this._content = content;

    if (needSave) this._needSave = needSave;
  }

  get needSave() {
    return this._needSave;
  }

  set needSave(newvalue) {
    this._needSave = newvalue;
  }

  get type() {
    return this.meta._type;
  }

  get name() {
    return this._name;
  }

  set name(newvalue) {
    this._name = newvalue;
  }

  get path() {
    return this._path;
  }

  set path(newvalue) {
    this._path = newvalue;
  }
  get meta() {
    return this._meta;
  }

  set meta(newvalue) {
    this._meta = newvalue;
  }

  get banner() {
    return this.meta.banner;
  }

  set banner(newvalue) {
    this.meta.banner = newvalue;
  }
};
