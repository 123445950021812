import { convertToString } from "./helper2";





export class SettingsManager {
      private externalStorage;

      constructor() {
            this.externalStorage = localStorage;
      }

      getSetting(key: string): string | null {
            var storage = this.externalStorage.getItem(key);
            return storage;// ? JSON.parse(storage) : defaultSettings;
      }

      setSetting(key: string, value: any): void {
            try {
                  const stringValue = convertToString(value);
                  this.externalStorage.setItem(key, stringValue);
            } catch (error) {
                  console.error(`Error while set "${key}":`, error);
            }
      }
      encrypt(data: string) {
            return data;
      }
      decrypt(data: string) {
            return data;
      }
}