import { useHistory } from "react-router-dom";
import LeftMenu from "../components/LeftMenu";
import RightPane from "../components/RightPane";
import { UserManager } from "../utilities/UsersManager";
import { route_login, setting_key_token } from "../utilities/consts";
import Cookies from "js-cookie";
import { SettingsManager } from "../utilities/SettingsManager";


export default function MainPage() {
  var history = useHistory();
  var um = new UserManager(null, new SettingsManager());

  if (um.is_user_logged_in() === false || !Cookies.get(setting_key_token) ) {
    history.push(route_login);
    return;
  }

  return (
    <>
      <div className="main-page">
        <div>
          <LeftMenu />
        </div>
        <div style={{ overflow: "hidden" }}>
          <RightPane />
        </div>
      </div>
    </>
  );
}
