import { Fragment, useContext, useEffect, useRef, useState } from "react";

import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";

// eslint-disable-next-line
// eslint-disable-next-line
// eslint-disable-next-line
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
// eslint-disable-next-line
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import PageMainBanner from "./Banner";
import { BigNotePlugin } from "./plugins/BigNotePlugin";
import OnChangePlugin from "./plugins/ChangePlugin";
import PlaygroundAutoLinkPlugin from "./plugins/LinkPlugin";
import { MyCustomAutoFocusPlugin } from "./plugins/MyCustomAutoFocusPlugin";
import CodeHighlightPlugin from "./plugins/codeHighlight";
import EditorToolbar from "./toolbar";
import { AppContext } from "../../utilities/storage";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import TreeViewPlugin from "./plugins/treeView";
import ImageZPlugin from "./plugins/ImagePlugin/image_plugin";
import YouTubePlugin from "./plugins/youtubePlugin/youtube_plugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { AppManager } from "../../utilities/AppManager";
import { CLEAR_HISTORY_COMMAND } from "lexical";

// Global error handler

export function Editor() {
  const editor = useLexicalComposerContext();
  const { EditorRef } = useContext(AppContext);
  /** @type {import("../../utilities/storage").AppContextType} */
  const appContext = useContext(AppContext);
  const myeditorRef = useRef(null);
  // const am = new AppManager(appContext);
  // var meta = useRef(am.getActivePageMetaInfoOrDefault());

  //const [editorState, setEditorState] = useState();
  //const [editorRef] = useRef();

  function onChange(editorState) {
    //Use this function to tell if the editor is changed
    //console.log("in onchange");
    //setEditorState(editorState);
    //console.log("-----------",editorRef.current);
    //    const editorStateJSON = editorState.toJSON();
    EditorRef.current = editor[0];
  }

  useEffect(() => {
    //console.log("in Editor useEffect .............................");
    //  setMeta(am.getActivePageMetaInfo());
    // console.log(am.getActivePageMetaInfo());
    //meta.current = am.getActivePageMetaInfoOrDefault();

  }, [editor, appContext]);


  useEffect(() => {

   // console.log("in e=useeffect ", appContext.isADFirstLoadingDone(), appContext.getActiveDocumentData());
    if (appContext.isADFirstLoadingDone() === false && appContext.getActiveDocumentData() != null) {
      var am = new AppManager(appContext);
      var data = appContext.getActiveDocumentData();

      var state = editor[0].parseEditorState(data);
      editor[0].setEditorState(state);

      // Clear History of editor
      editor[0].dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);


      appContext.setADFirstLoadingDone(true);
      am.setIsPageNeedSave(false);
    }

  }, [appContext.isADFirstLoadingDone()]);


  // if (appContext.isADFirstLoadingDone() === false) {
  //   return "not ready yet";
  // }

  // var meta1=meta.current;
  return (
    <Fragment>
      <Fragment>
        <EditorToolbar />
        <PageMainBanner src={"https://w0.peakpx.com/wallpaper/1009/531/HD-wallpaper-plasma-abstract-color-colour.jpg"} size="cover" />
        <RichTextPlugin spellcheck="false" contentEditable={<ContentEditable className="contentEditable" />} placeholder={<div>Start writing</div>} ErrorBoundary={LexicalErrorBoundary} />
        {/* <ListPlugin /> */}

        {/* For undo/redo */}
        <HistoryPlugin />

        {/* Custom Plugin */}
        <BigNotePlugin />
        <MarkdownShortcutPlugin />
        <MyCustomAutoFocusPlugin />
        <ListPlugin />
        <CheckListPlugin />
        {/* For Debug */}
        {/* <TreeViewPlugin /> */}
        <PlaygroundAutoLinkPlugin />
        <CodeHighlightPlugin />

        <OnChangePlugin onChangeCallback={onChange} />
        <EditorRefPlugin editorRef={EditorRef} />
        <ClearEditorPlugin />
        <YouTubePlugin />
        <ImageZPlugin />
      </Fragment>
    </Fragment>
  );
}
