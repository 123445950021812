//@ts-check
import { PageBanner_dto, PageMeta_dto, Page_dto } from "./Dtos";
import { url_get_file_data, url_get_files_list, url_move_file, url_save_file, url_update_icon } from "./consts";
import { enum_page_type } from "./enums";
import { apiCall } from "./fetchApi";
// eslint-disable-next-line
import { AppContextType } from "./storage";

export class AppManager {
  /**
   * @param {AppContextType} appContext
   */
  constructor(appContext) {
    this.appContext = appContext;
  }
  /**
   * @param {string} path
   * @param {Function} successCallback
   * @param {Function} failCallback
   */
  async api_getPagesListInPath(path, successCallback, failCallback) {
    var call = await apiCall(url_get_files_list, { pagePath: path }, "Get", null );
    if (call.success === true) {
      successCallback && successCallback(call.data);
    } else {
      failCallback && failCallback();
    }
  }

  /**
   * @param {string} path
   * @param {boolean} includeMeta
   * @param {Function} successCallback
   * @param {Function} failCallback
   */
  async api_getPageData(path, includeMeta, successCallback, failCallback) {
    var call = await apiCall(url_get_file_data, { pagePath: path, includeMeta }, "Get", null);
    if (call.success === true) {
      successCallback && successCallback(call.data);
    } else {
      failCallback && failCallback();
    }
  }

  /**
   * @param {string} path
   * @param {any} pageData
   * @param {any} metaData
   * @param {string} textData
   * @param {string} DocumentType
   * @param {Function} successCallback
   * @param {Function} failCallback
   */
  async api_savePage(path, pageData, textData, metaData, DocumentType, successCallback, failCallback) {
    console.info(`Save file (${path})`, "text length: " + textData.length, " data: " + pageData, "Meta: ", metaData);
    var call = await apiCall(url_save_file, {}, "Post", { docType: DocumentType, pagePath: path, data: pageData, text: textData, meta: metaData });
    if (call.success === true) {
      successCallback && (await successCallback(call.data));
    } else {
      failCallback && (await failCallback());
    }
  }

  /**
   * Copy or move the page
   * @param {string} sourcePagePath
   * @param {string} destinationPagePath
   * @param {string} process
   * @param {number} order
   * @param {Function} successCallback
   * @param {Function} failCallback
   */

  async api_movePage(sourcePagePath, destinationPagePath, process, order,  successCallback, failCallback) {
    console.info(`change ${sourcePagePath} to ${destinationPagePath} via ${process}`);

    var call = await apiCall(url_move_file, {}, "Post", { sourcePagePath, destinationPagePath, process , order});
    if (call.success === true) {
      successCallback && (await successCallback(call.data));
    } else {
      failCallback && (await failCallback("error while move/copy page"));
    }
  }

  /**
  *  updatePageIcon
  * @param {string} pagePath
  * @param {string} icon 
  * @param {Function} successCallback
  * @param {Function} failCallback
  */

  async api_updatePageIcon(pagePath, icon, successCallback, failCallback) {
     
    var call = await apiCall(url_update_icon, {}, "Post", { pagePath, icon });
    if (call.success === true) {
      successCallback && (await successCallback(call.data));
    } else {
      failCallback && (await failCallback("error while update page icon"));
    }
  }



  /**
   * @return {import("./storage").AppSettings}
   */
  getAppSettings() {
    if (!this.appContext.getSettings()) {
      throw new Error("App context settings is not init yet");
    }
    return this.appContext.getSettings();
  }

  /**
   * @param {string} pageName
   * @param {string} pagePath
   */
  setActivePage(pageName, pagePath) {
    var currentPage = this.getActivePage();
    currentPage.name = pageName;
    currentPage.path = pagePath;
    this.appContext.updateAppContext({ selectedPage: currentPage });
  }

  /**
   * @return {Page_dto}
   */
  getActivePage() {
    //console.count("x");
    //  console.trace();
    const selectedPage = this.getAppSettings().selectedPage;
    if (!selectedPage) {
      throw new Error("No selected page");
    }
    return selectedPage;
  }

  isCurrentPageNeedSave() {
    // or null
    var selectedPage = this.getActivePage();
    return selectedPage.needSave || false;
  }

  /**
   * @param {boolean} needSave
   */
  setIsPageNeedSave(needSave) {
    var sp = this.getActivePage();
    sp.needSave = needSave;
    this.appContext.updateAppContext({ selectedPage: sp });
  }

  /**
   * This function will return background, icon, type, ....
   * @returns {PageMeta_dto} banner
   */
  getActivePageBannerOrDefault() {
    var sp = this.getActiveMetaInfoOrDefault();

    return sp.banner;
  }

  /**
   * @param {PageBanner_dto} banner
   */
  setActivePageBannerInfo(banner) {
    var sp = this.getActivePage();
    sp.meta.banner = banner;
    this.appContext.updateAppContext({ selectedPage: sp });
  }

  getActiveMetaInfoOrDefault() {
    var sp = this.getActivePage();
    if (sp.meta === undefined) {
      sp.meta = new PageMeta_dto({ banner: {}, type: enum_page_type.rich_text_document });
    }

    return sp.meta;
  }

  /**
   * @param {PageMeta_dto} meta
   */
  setActivePageMetaInfo(meta) {
    var sp = this.getActivePage();
    sp.meta = meta;
    this.appContext.updateAppContext({ selectedPage: sp });
  }
}
