//@ts-check

import { url_user_login, url_user_logout, url_user_logout_all } from "./consts";
import { apiCall } from "./fetchApi";
import Cookies from "js-cookie";
import { AppContextType } from "./storage";
import { UserManager } from "./UsersManager";
import { SettingsManager } from "./SettingsManager";
// Define custom types

type Point = {
      x: number;
      y: number;
}

type Rectangle = {
      topLeft: Point;
      bottomRight: Point;
}

// Function that takes Point and Rectangle as parameters

export function isPointInsideRectangle(point: Point, rectangle: Rectangle): boolean {
      const { x, y } = point;
      const { topLeft, bottomRight } = rectangle;
      const { x: topLeftX, y: topLeftY } = topLeft;
      const { x: bottomRightX, y: bottomRightY } = bottomRight;

      return x >= topLeftX && x <= bottomRightX && y >= topLeftY && y <= bottomRightY;
}

// Example usage

const myPoint: Point = { x: 5, y: 5 };
const myRectangle: Rectangle = {
      topLeft: { x: 0, y: 0 },
      bottomRight: { x: 10, y: 10 }
};

const isInside = isPointInsideRectangle(myPoint, myRectangle);
console.log(isInside); // Output: true or false based on the point's position inside the rectangle



// Convert type to string
export function convertToString(value: any): string {
      var result = "";
      if (typeof value === 'object' && value !== null) {
            result = JSON.stringify(value);
      } else if (typeof value === 'boolean' || typeof value === 'number') {
            result = value.toString();
      } else {
            throw new Error(`Unsupported value type: ${typeof value}`);
      }
      return result;


}

// Api Call


type httpResult = {
      success: boolean;
      data: any;
}

export async function handleLogin(email: string, password: string): Promise<any> {
      var request_data = { "userId": email, password };

      var result = (await apiCall(url_user_login, null, "Post", request_data) as unknown) as httpResult;


      if (result.success) {
            var response = result.data && result.data.result;

            Cookies.set("token", response.token, { expires: 3 * 30 });
            return { result: true };
      } else {
            var msg1 = (result && result.data && result.data.errors) || "";
            return { result: false, error: msg1 };
      }
}

export async function handleLogout(logoutAll: boolean, successCallbak: () => {}, errorCallback: (error: string) => {}, alwaysCallbak: () => {}): Promise<any> {

      var um = new UserManager(null, new SettingsManager());
      var url = "";
      if (logoutAll === true) {
            url = url_user_logout_all;
      }
      else {
            url = url_user_logout;
      }

      var result = (await apiCall(url, null, "Post") as unknown) as httpResult;

      if (result.success) {
            Cookies.remove("token");
            um.updateUserLoginData(false);
            successCallbak && successCallbak();
            return true;
      } else {
            var msg1 = (result && result.data && result.data.errors) || "";
            errorCallback && errorCallback(msg1);
      }

}