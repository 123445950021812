import { LexicalEditor } from "lexical";
import { TextRichDocument } from "./DocumentHandlers/TextRichDocument";
import { enum_page_type } from "./enums";


export class BaseDocument {

    public DocumentPath: string;
    public type: MyDocumentType;

    constructor(DocumentPath: string, type: MyDocumentType) {
        this.DocumentPath = DocumentPath;
        this.type = type;
    }
};


export interface IDocument {
    OpenDocument(apiData: any, meta: any, successCallback: (...args: any[]) => unknown, failCallback: (...args: any[]) => unknown): any;
    SaveDocument(fileName: string): boolean;
    ExportDocument(fileName: string, format: string): boolean;
    IsDocumentSaved(): boolean;
    UpdateDocumentMeta(): boolean;
};

export enum MyDocumentType { text, gallery };



