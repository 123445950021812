import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../utilities/storage';
import { AppManager } from '../../utilities/AppManager';
import { MyDocumentType } from '../../utilities/IDocument';



export function GalleryEditor(): React.ReactElement {

  const [mdata, setmdata] = useState([]);
  /** @type {import('../../utilities/storage').AppContextType} */
  var appContext = useContext(AppContext);


  useEffect(() => {
    if (appContext.isADFirstLoadingDone() === false) {
      var am = new AppManager(appContext);
      var data = appContext.getActiveDocumentData();
      setmdata(data);
      appContext.setADFirstLoadingDone(true);
      am.setIsPageNeedSave(false);
    }
  }, [])

  function handleSave() {
    var am = new AppManager(appContext);
    var ap = am.getActivePage();
    am.api_savePage(ap.path, mdata, '', ap.meta, MyDocumentType.gallery.toString(), () => { alert("done") }, () => { alert("fail") });
  }

  function handle() {
    var t = prompt("url?");
    if (t && t.trim() !== '') {
      var d = mdata;
      d.push(t);
      setmdata(o => [...d]);
    }

  }
  return (
    <>
      <div>GalleryEditor</div>
      <button onClick={handle}>add</button>
      <div className='gallery'>
        <div className='toolbar1'>
          <button onClick={handleSave}>Save</button>
        </div>
        <div className='gallery1'>

        {mdata && mdata.map((item, index) => { return <img src={item} alt={"image-" + index} style={{ height: "200px" }} /> })}
        </div>
      </div>

    </>
  )
};


//import React from 'react';

export function ComponentA(): React.ReactElement {
  return <div>aaa</div>;
};


