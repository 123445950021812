import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils";
import { $createParagraphNode, $insertNodes, $isRootOrShadowRoot, COMMAND_PRIORITY_EDITOR, createCommand } from "lexical";
import { useEffect } from "react";
import { $createYouTubeNode, YouTubeNode } from "./youtube_node";

export const INSERT_YOUTUBE_COMMAND = createCommand("INSERT_YOUTUBE_COMMAND");

export default function YouTubePlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([YouTubeNode])) {
      throw new Error("YouTubePlugin: Pls add Youtube node");
    }
    return mergeRegister(
      editor.registerCommand(
        INSERT_YOUTUBE_COMMAND,
        (payload) => {
          if (payload) {
            const youTubeNode = $createYouTubeNode({ videoID: payload });
            console.log("The payload for INSERT_YOUTUBE_COMMAND", payload);
            $insertNodes([youTubeNode]);
            if ($isRootOrShadowRoot(youTubeNode.getParentOrThrow())) {
              const node = $wrapNodeInElement(youTubeNode, $createParagraphNode);

              node.selectEnd();
            }
          } else {
            console.warn("[YouTubePlugin] Can't create youtube element no id is provided");
            return false;
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  return null;
}
