import { $createCodeNode } from "@lexical/code";
import { TOGGLE_LINK_COMMAND } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { $createHeadingNode } from "@lexical/rich-text";
import { $patchStyleText, $setBlocksType } from "@lexical/selection";
import { $createParagraphNode, $getSelection, $isRangeSelection, $isTextNode, CLEAR_EDITOR_COMMAND, FORMAT_ELEMENT_COMMAND } from "lexical";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { AppManager } from "../../utilities/AppManager";

import { AppContext } from "../../utilities/storage";

import { ToolbarBigNoteButton, ToolbarBoldButton, ToolbarIndent, ToolbarItalicButton, ToolbarSeparator, ToolbarStrikeThroughButton, ToolbarUnderLineButton } from "./plugins/TextFormatPlugins";

import { INSERT_IMAGE_COMMANDZ } from "./plugins/ImagePlugin/image_plugin";
import { INSERT_YOUTUBE_COMMAND } from "./plugins/youtubePlugin/youtube_plugin";
import { PageManager } from "../../utilities/PageManger";

function ToolbarParagraphButton() {
  const [editor] = useLexicalComposerContext();

  function handleClick() {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
        //$wrapNodes(selection, () => $createHeadingNode("h1"));
      }
    });
  }

  return (
    <button className="toolbar-btn" onClick={() => handleClick()}>
      {"P"}
    </button>
  );
}

function ToolbarHeading({ level = 1 }) {
  const [editor] = useLexicalComposerContext();

  function handleClick() {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createHeadingNode("h" + level));
        //$wrapNodes(selection, () => $createHeadingNode("h1"));
      }
    });
  }

  return (
    <button className="toolbar-btn" onClick={() => handleClick()}>
      {"H" + level}
    </button>
  );
}

function ToolbarCodeBlock() {
  const [editor] = useLexicalComposerContext();

  function handleClick() {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createCodeNode());
        //$wrapNodes(selection, () => $createHeadingNode("h1"));
      }
    });
  }

  return (
    <button className="toolbar-btn" onClick={() => handleClick()}>
      {"</>"}
    </button>
  );
}
function ToolbarTextDirection({ stylee = "left" }) {
  const [editor] = useLexicalComposerContext();

  function handleClick() {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, stylee);
  }

  return (
    <button className="toolbar-btn" onClick={() => handleClick()}>
      {stylee}
    </button>
  );
}

function ToolbarAddLink() {
  const [editor] = useLexicalComposerContext();

  function handleClick() {
    console.log("in link");
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    }
  }

  return (
    <button className="toolbar-btn" onClick={() => handleClick()}>
      {"<a>"}
    </button>
  );
}

function ToolbarSetStyles() {
  const [editor] = useLexicalComposerContext();

  function handleClick() {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $patchStyleText(selection, { color: "red" });
        $patchStyleText(selection, { "background-color": "orange" });
      }
    });
  }

  return (
    <button className="toolbar-btn" onClick={() => handleClick()}>
      {"Style"}
    </button>
  );
}

function ToolbarClearStyles() {
  const [editor] = useLexicalComposerContext();

  function handleClick() {
    editor.update(() => {
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
      return;
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        selection.getNodes().forEach((node) => {
          if ($isTextNode(node)) {
            node.setFormat(0);
            node.setStyle("");
            //$getNearestBlockElementAncestorOrThrow(node).setFormat("");
          }
          if ($isDecoratorBlockNode(node)) {
            node.setFormat("");
          }
        });
      }
    });
  }

  return (
    <button className="toolbar-btn" onClick={() => handleClick()}>
      {"no style"}
    </button>
  );
}

export function ToolbarYoutubeButton() {
  const [editor] = useLexicalComposerContext();

  function HandleFillingYoutubeVideo() {
    return "QleQodiNA5M";
    const url = prompt("Enter the URL of the YouTube video:", "");
    if (!url) {
      return null;
    }
    const match = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(url);

    const id = match ? (match?.[2].length === 11 ? match[2] : null) : null;

    if (id != null) {
      return id;
    }

    return null;
  }

  function handleClick() {
    editor.dispatchCommand(INSERT_YOUTUBE_COMMAND, HandleFillingYoutubeVideo());
  }

  return (
    <button className="toolbar-btn" onClick={() => handleClick()}>
      {"video"}
    </button>
  );
}

export function ToolbarImagePlugin() {
  const [editor] = useLexicalComposerContext();

  function FillURL() {
    const srcfile = prompt("Enter the URL of the image:", "");

    return srcfile;
  }

  const handleClick = (payload) => {
    editor.dispatchCommand(INSERT_IMAGE_COMMANDZ, payload);
  };

  return (
    <button
      onClick={() =>
        handleClick({
          altText: "URL image",
          src: FillURL(),
        })
      }
      className="toolbar-btn">
      <span className="text">Image</span>
    </button>
  );
}

export function ToolbarImagePlugin2() {
  //const [editor] = useLexicalComposerContext();

  function FillURL() {
    //const srcfile = prompt("Enter the URL of the image:", "");
    return "https://miro.medium.com/v2/resize:fit:320/0*4SigC2vO15n8XIT1.jpeg";
    //return srcfile;
  }

  const handleClick = (payload) => {
    // editor.dispatchCommand(INSERT_INLINE_IMAGE_COMMAND, payload);
  };

  return (
    <button onClick={() => handleClick({ altText: "Life is good", src: FillURL(), showCaption: true, position: "center" })} className="toolbar-btn">
      <span className="text">Image2</span>
    </button>
  );
}

function ToolbarSaveButton() {
  const appContext = useContext(AppContext);
  var am = new AppManager(appContext);
  const pm = new PageManager(am);
  const [editor] = useLexicalComposerContext();
  //var backColor = "clean-status";
  const [bg, setBg] = useState("clean-status");

  function handleClick() {
    const activePath = am.getActivePage().path;

    if (!activePath) {
      alert("Please select a page first");
    }

    pm.HandleCurrentPageSave(
      appContext,
      editor,
      () => {
        am.setIsPageNeedSave(false);
      },
      () => {
        alert("error while saving " + activePath);
      }
    );
  }

  useEffect(() => {
    //console.log("Check need save");
    if (am.isCurrentPageNeedSave() === false) {
      setBg("clean-status");
    } else {
      setBg("dirty-status");
    }
  }, [appContext]);

  React.useLayoutEffect(() => {
    const onKeyDown = (event) => {
      // "Ctrl" or "Cmd" + "s"
      if ((event.ctrlKey || event.metaKey) && event.which === 83) {
        event.preventDefault();
        console.log("in save command");

        pm.HandleCurrentPageSave(
          appContext,
          editor,
          () => {
            am.setIsPageNeedSave(false);
          },
          () => {
            alert("Saving error");
          }
        );
      }
    };

    return editor.registerRootListener((rootElement, prevRootElement) => {
      if (prevRootElement !== null) {
        prevRootElement.removeEventListener("keydown", onKeyDown);
      }
      if (rootElement !== null) {
        rootElement.addEventListener("keydown", onKeyDown);
      }
    });

    // eslint-disable-next-line
  }, [editor, appContext]);

  // console.log("backColor", bg);
  return (
    <button className={"toolbar-btn " + bg} onClick={() => handleClick()}>
      {"Save"}
    </button>
  );
}

function ToolbarToggleReadMode() {
  const [editor] = useLexicalComposerContext();

  function handleClick() {
    editor.setEditable(!editor.isEditable());
  }

  return (
    <button className="toolbar-btn" onClick={() => handleClick()}>
      {"View"}
    </button>
  );
}
export default function EditorToolbar() {
  //const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  // eslint-disable-next-line
  const [isBigNote, setIsBigNote] = useState(false);

  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        //console.count("update toolbar");
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          setIsBold(selection.hasFormat("bold"));
          setIsItalic(selection.hasFormat("italic"));
          setIsUnderline(selection.hasFormat("underline"));
          setIsStrikethrough(selection.hasFormat("strikethrough"));
        }
      });
    });
  }, [editor]);

  return (
    <>
      <div className="toolbar" style={{ display: "flex", justifyContent: "center" }}>
        <ToolbarBigNoteButton is_active={isBigNote} />
        <ToolbarSeparator />

        <ToolbarBoldButton is_active={isBold} />
        <ToolbarUnderLineButton is_active={isUnderline} />
        <ToolbarItalicButton is_active={isItalic} />
        <ToolbarStrikeThroughButton is_active={isStrikethrough} />
        <ToolbarSeparator />

        <ToolbarParagraphButton />
        <ToolbarSeparator />
        <ToolbarHeading level={1} />
        <ToolbarHeading level={2} />
        <ToolbarHeading level={3} />
        <ToolbarHeading level={4} />
        <ToolbarHeading level={5} />
        <ToolbarSeparator />

        <ToolbarTextDirection stylee={"left"} />
        <ToolbarTextDirection stylee={"center"} />
        <ToolbarTextDirection stylee={"right"} />
        <ToolbarTextDirection stylee={"justify"} />
        <ToolbarIndent />
        
          

        <ToolbarSeparator />
        <ToolbarAddLink />
        <ToolbarCodeBlock />
        <ToolbarSeparator />
        <ToolbarSaveButton />
        <ToolbarToggleReadMode />
        <ToolbarSeparator />
        <ToolbarSetStyles />
        <ToolbarClearStyles />
        <ToolbarYoutubeButton />
        <ToolbarImagePlugin />
        <ToolbarImagePlugin2 />
      </div>
    </>
  );
}
