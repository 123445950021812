import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const useModalYesNoCancel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const mymodal = useRef(null);

  const onClose = useCallback(() => {
    mymodal && mymodal.current.close();
    setIsOpen(false);
    setModalContent(null);
  }, []);

  useEffect(() => {
    if (isOpen === true) {
      mymodal && mymodal.current.showModal();

      if (mymodal && modalContent && modalContent.canDismiss === true) {
        var mm = mymodal.current;
        const handleClickOutside = (event) => {
          var rect = mm.getBoundingClientRect();
          var isInDialog = rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left <= event.clientX && event.clientX <= rect.left + rect.width;
          if (!isInDialog) {
            onClose();
          }
        };

        mm.addEventListener("click", handleClickOutside);
        return () => {
          console.log("Unregister Dialog out click");
          mm.removeEventListener("click", handleClickOutside);
        };
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (!mymodal || mymodal.current) {
      return;
    }
  }, [isOpen, modalContent]);

  const modal = () => {
    if (modalContent === null) {
      return <></>;
    }
    const { title, body, canDismiss, positiveText = "ok", negativeText = "close", cancelText = "Cancel", positiveCallback, negativeCallback, cancelCallback } = modalContent;

    if (isOpen === false)
      return (
        <>
          <span>Hidden</span>
        </>
      );

    return (
      <dialog className="my-modal" ref={mymodal}>
        {/* {imgUrl && <div className="logo-image" style={{ width: "100%", height: "100px", backgroundImage: "url('" + imgUrl + "')" }}></div>} */}

        <div>
          <div>{title}</div>
          <div>
            <button onClick={() => onClose()}>Close</button>
          </div>
        </div>
        <hr />
        <div>{body}</div>
        <hr />

        <div>
          <button
            onClick={() => {
              //   handleClose && handleClose();
              onClose();
              positiveCallback && positiveCallback();
            }}>
            {positiveText}
          </button>
          <button
            onClick={() => {
              //   handleClose && handleClose();
              onClose();
              negativeCallback && negativeCallback();
            }}>
            {negativeText}
          </button>
          <button
            onClick={() => {
              //  handleClose && handleClose();
              onClose();
              cancelCallback && cancelCallback();
            }}>
            {cancelText}
          </button>
        </div>
      </dialog>
    );
  };

  /**
   * @param {Object} title
   * @param {Function} positiveCallback
   * @param {Function} negativeCallback
   * @param {Function} cancelCallback
   */
  const showModal = ({ title, body, canDismiss, positiveText = "Yes", negativeText = "No", cancelText = "cancel", positiveCallback = null, negativeCallback = null, cancelCallback = null }) => {
    setModalContent({
      canDismiss,
      body,
      title,
      positiveText,
      negativeText,
      cancelText,
      positiveCallback,
      negativeCallback,
      cancelCallback,
    });
    setIsOpen(true);
    mymodal && mymodal.current && mymodal.current.showModal();
  };

  return { isOpen, onClose, showModal, modal };
};



export const useModalNoControls = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const mymodal = useRef(null);

  const onClose = useCallback(() => {
    mymodal && mymodal.current.close();
    setIsOpen(false);
    setModalContent(null);
  }, []);

  useEffect(() => {
    if (isOpen === true) {
      mymodal && mymodal.current.showModal();

      if (mymodal && modalContent && modalContent.canDismiss === true) {
        var mm = mymodal.current;
        const handleClickOutside = (event) => {
          var rect = mm.getBoundingClientRect();
          var isInDialog = rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left <= event.clientX && event.clientX <= rect.left + rect.width;
          if (!isInDialog) {
            onClose();
          }
        };

        mm.addEventListener("click", handleClickOutside);
        return () => {
          console.log("Unregister Dialog out click");
          mm.removeEventListener("click", handleClickOutside);
        };
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (!mymodal || mymodal.current) {
      return;
    }
  }, [isOpen, modalContent]);

  const modal = () => {
    if (modalContent === null) {
      return <></>;
    }
    const { title, body, canDismiss, positiveText = "ok", negativeText = "close", cancelText = "Cancel", positiveCallback, negativeCallback, cancelCallback } = modalContent;

    if (isOpen === false)
      return (
        <>
          <span>Hidden</span>
        </>
      );

    return (
      <dialog className="my-modal" ref={mymodal}>
        {/* {imgUrl && <div className="logo-image" style={{ width: "100%", height: "100px", backgroundImage: "url('" + imgUrl + "')" }}></div>} */}

        <div>
          <div>{title}</div>
          <div>
            <button onClick={() => onClose()}>Close</button>
          </div>
        </div>
        <hr />
        <div>{body}</div>
        <hr />

        <div>
         
          
        </div>
      </dialog>
    );
  };

  /**
   * @param {Object} title
   * @param {Function} positiveCallback
   * @param {Function} negativeCallback
   * @param {Function} cancelCallback
   */
  const showModal = ({ title, body, canDismiss,    cancelCallback = null }) => {
    setModalContent({
      canDismiss,
      body,
      title,  
      cancelCallback,
    });
    setIsOpen(true);
    mymodal && mymodal.current && mymodal.current.showModal();
  };

  return { isOpen, onClose, showModal, modal };
};
