import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { useContext, useEffect } from "react";
import { AppManager } from "../../../utilities/AppManager";
import { AppContext } from "../../../utilities/storage";

export default function OnChangePlugin({ onChangeCallback }) {
  /** @type {import("../../../utilities/storage").AppContextType} */
  const appContext = useContext(AppContext);
  const am = new AppManager(appContext);

  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const unregister = mergeRegister(
      editor.registerDecoratorListener((x) => {
        //console.log("decorator listner");
      }),

      // editor.registerMutationListener((k,x) => {
      //   console.log("MutationListener listner");
      // }),

      editor.registerRootListener((x) => {
        //console.log("RootListener listner");
      }),

      editor.registerUpdateListener(({ prevEditorState, editorState, dirtyElements, dirtyLeaves }) => {
        //console.log("update listner");

        if (dirtyElements.size === 0 && dirtyLeaves.size === 0) return;
        if (appContext.isADFirstLoadingDone() === true) {
          am.setIsPageNeedSave(true);
        }

        // const contentSize = editorState.read(() => $getRoot().getTextContentSize());
        // const prevContentSize = prevEditorState.read(() => $getRoot().getTextContentSize());

        // const prevKeys = prevEditorState.read(() => $getRoot().getChildrenKeys());
        // const nextKeys = editorState.read(() => $getRoot().getChildrenKeys());

        // function arraysEqual(arr1, arr2) {
        //   if (arr1.length !== arr2.length) {
        //     return false;
        //   }
        //   return arr1.every((num) => arr2.includes(num));
        // }

        // if (contentSize !== prevContentSize || !arraysEqual(prevKeys, nextKeys)) {
        //   am.setIsPageNeedSave(true);
        // }
      })
    );
    return () => {
      unregister();
    };
    // eslint-disable-next-line
  }, [editor, onChangeCallback]);
}
