import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { handleLogin } from "../utilities/helper2";
import Button from "./shared/Button";
import ElevatedCard from "./shared/ElevatedCard";
import { ErrorsComponents } from "./shared/errors";
import { AppContext } from "../utilities/storage";
import { UserManager } from "../utilities/UsersManager";
import { SettingsManager } from "../utilities/SettingsManager";


type draggedItem = any; // Replace `any` with the actual type if known
type setDraggedItem = (item: draggedItem) => void;

export interface AppContextType {
    clearSettings: () => void;
    getSettings: () => any; // Replace `any` with the actual return type of getSettings
    updateAppContext: (data: any, toDelete?: any) => void;
    EditorLoadedFirstTime: boolean;
    draggedItem: draggedItem; // Assuming draggedItem is a type or object; adjust as needed
    setDraggedItem: setDraggedItem;
    updateTreeItems: () => void;
    getTreeItems: () => any; // Replace `any` with the actual return type of getTreeItems
    isTreeItemsReady: boolean;
    setActiveDocumentData: () => void;
    getActiveDocumentData: () => any; // Replace `any` with the actual return type of getActiveDocumentData
    setADFirstLoadingDone: () => void;
    isADFirstLoadingDone: () => boolean;

}

export default function LoginComponent() {
    var context = (useContext(AppContext) as unknown) as AppContextType;

    var history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState<string[]>([]);
    const [disabled, setDisabled] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const txtpw = useRef<HTMLInputElement>(null);

    async function handleBasicLogin() {
        var errors: string[] = [];

        setErrors(errors);

        if (!email) {
            setErrors(["Please enter a valid email address"]);
            return;
        }
        if (!password) {
            setErrors(["Please enter a password"]);
            return;
        }
        setIsBusy(true);
        var result = await handleLogin(email, password);
        if (result.result === true) {
            var um = new UserManager(null, new SettingsManager());
            um.updateUserLoginData(true)
            history.push("/");
        } else {
            setErrors([result.error]);
        }
        setIsBusy(false);
    }

    async function handleResetPassword() {
        history.push("/resetpassword");
    }



    useEffect(() => {

    }, []);

    return (
        <Fragment>
            <div className="login-page mx-auto">
                <p className="page-title ns">Login</p>

                <ElevatedCard isBusy={isBusy} className=" m-2 ns ">
                    <hr className="my-2" />
                    <div className="d-flex justify-content-center">
                        <span>Please Enter Your Id and password</span>
                    </div>
                    <div className="details m-2">
                        <div className="labeled-input">
                            <span>Email</span>
                            <br />
                            <input
                                type="email"
                                value={email}
                                onKeyUp={(e) => e.key === "Enter" && txtpw && txtpw.current && txtpw.current.focus()}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </div>

                        <div className="labeled-input">
                            <span>Password</span>
                            <br />
                            <input
                                ref={txtpw}
                                type="password"
                                value={password}
                                onKeyUp={(e) => e.key === "Enter" && handleBasicLogin()}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </div>
                    </div>

                    <div className="d-flex my-1 justify-content-start">
                        <button className=" lbtn-green" disabled={disabled} onClick={() => handleResetPassword()}>
                            Forgot Password
                        </button>
                    </div>
                    {/* <p className="mx-3">
                        By creating an account you agree to the{" "}
                        <a target="_blank" rel="noopener noreferrer" href={route_privacy_policy}>
                            Privacy policy
                        </a>
                        and the <a target="_blank" rel="noopener noreferrer" href={route_terms_of_use}>Terms of use</a>.
                    </p> */}
                    <div className="d-flex m-2 justify-content-center">
                        <Button className="gbutton btn-green w-100" disabled={disabled} isBusy={isBusy} onClick={() => handleBasicLogin()}>
                            Login
                        </Button>
                    </div>
                    <ErrorsComponents errors={errors} />

                </ElevatedCard>
            </div>
        </Fragment>
    );
}
