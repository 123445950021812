import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { FORMAT_TEXT_COMMAND, INDENT_CONTENT_COMMAND, OUTDENT_CONTENT_COMMAND } from "lexical";
import * as React from "react";
import { INSERT_BIGNOTE_COMMAND } from "./BigNotePlugin";

export function ToolbarIndent({ dir, is_active = false }) {
  const [editor] = useLexicalComposerContext();

  function handleClick(dir) {
    editor.dispatchCommand(dir, undefined);
  }

  return (
    <>
      <button className={"toolbar-btn" + (is_active ? " active " : "")} onClick={() => handleClick(OUTDENT_CONTENT_COMMAND)}>
        {"<-"}
      </button>
      <button className={"toolbar-btn" + (is_active ? " active " : "")} onClick={() => handleClick(INDENT_CONTENT_COMMAND)}>
        {"->"}
      </button>
    </>
  );
}

export function ToolbarSeparator() {
  return <div className="button-separator"> | </div>;
}
export function ToolbarBoldButton({ is_active = false }) {
  const [editor] = useLexicalComposerContext();

  function handleClick() {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
  }

  return (
    <button className={"toolbar-btn" + (is_active ? " active " : "")} onClick={handleClick}>
      {"B"}
    </button>
  );
}
export function ToolbarUnderLineButton({ is_active = false }) {
  const [editor] = useLexicalComposerContext();

  function handleClick() {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
  }

  return (
    <button className={"toolbar-btn" + (is_active ? " active " : "")} onClick={handleClick}>
      {"U"}
    </button>
  );
}
export function ToolbarStrikeThroughButton({ is_active = false }) {
  const [editor] = useLexicalComposerContext();

  function handleClick() {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
  }

  return (
    <button className={"toolbar-btn" + (is_active ? " active " : "")} onClick={handleClick}>
      {"--"}
    </button>
  );
}

export function ToolbarItalicButton({ is_active = false }) {
  const [editor] = useLexicalComposerContext();

  function handleClick() {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
  }

  return (
    <button className={"toolbar-btn" + (is_active ? " active " : "")} onClick={handleClick}>
      {"I"}
    </button>
  );
}

export function ToolbarBigNoteButton({ is_active = false }) {
  const [editor] = useLexicalComposerContext();

  function handleClick() {
    editor.dispatchCommand(INSERT_BIGNOTE_COMMAND, null);
  }

  return (
    <button className={"toolbar-btn" + (is_active ? " active " : "")} onClick={handleClick}>
      {"BN"}
    </button>
  );
}