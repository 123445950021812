import { useContext, useState } from "react";

 
 
import { useModalYesNoCancel } from "../utilities/component_helper";
import { AppContext } from "../utilities/storage";

export function useLeftMenu({ editor, am, pm1 }) {
  /** @type {import("../utilities/storage").AppContextType} */
  const appContext = useContext(AppContext);

  /** @type PageManager */
  var pm = pm1;
  // Main tree with all items

  const [isBusy, setIsBusy] = useState(true);
  const [selectedPath, setSelectedPath] = useState(null);
  const dialog3b = useModalYesNoCancel();

 
  const EditorLoadedFirstTime = appContext.EditorLoadedFirstTime;

  async function LoadFirstTime(pageName, pagePath) {
   
    console.log("update first time status", EditorLoadedFirstTime.current);
    EditorLoadedFirstTime.current = true;
    await pm.ChangeActivePageAndGetPageDataAndUpdateEditor(appContext, pageName, pagePath, editor, null, null);
  }

  function getPageSubPages(node) { 
    setIsBusy(true);

    am.api_getPagesListInPath(
      "/",
      (apiData) => {
        var fullTreeItems = pm.BuildTreeItemsDtoRecursivly(apiData, node);
        //setTree(fullTreeItems);
        setIsBusy(false);
      },
      () => {
        alert("Some error while getting the tree");
        setIsBusy(false);
      }
    );
  }

  function handleOnCreateNewPage(pagePath, treeItemSuccessCallback) {
 
    pm.handleCreateNewPage(
      appContext,
      editor,
      dialog3b,
      pagePath,
      setIsBusy,
      (pageName) => {
        setIsBusy && setIsBusy(false);
        treeItemSuccessCallback && treeItemSuccessCallback(pageName);
      },
      () => {
        setIsBusy && setIsBusy(false);
        alert("Error while Creating new page");
      }
    );
  }

  function handleDeletePage(pagePath, treeItemSuccessCallback) {
   
    pm.handleDeletePage(
      appContext,
      dialog3b,
      pagePath,
      editor,
      setIsBusy,
      () => {
        treeItemSuccessCallback && treeItemSuccessCallback();
      },
      () => {
        alert("Error while Deleting page");
      }
    );
  }

  async function handleOpenPage1(newPagePath, newPageName, successCallback, failCallback) {
 
    const currentPage = am.getActivePage().path;
 
    if (!currentPage || (currentPage && currentPage !== newPagePath)) {
      //console.info("Open Page is clicked with : ", "", newPagePath);
      await pm.HandleLoadOtherPageIntoEditor(dialog3b.showModal, appContext, newPageName, newPagePath, editor, successCallback, failCallback);
    } else {
      console.warn("No need to reload same page");
    }
  }

  // accept array of required items to be expanded
  function setTreeItemExpandedStatus(itemPath, expanded) {
 
    /** @type {import("../utilities/storage").AppSettings} */
    var settings = appContext.getSettings();

    if (!(itemPath instanceof Array)) {
      itemPath = [itemPath];
    }

    var oldItems = settings.expandedItems || [];
    var change = false;
    if (expanded === true) {

      itemPath.forEach(element => {
        if (oldItems.indexOf(element) === -1) {
          oldItems.push(element);
          change = true;
        }

      });
      change && appContext.updateAppContext({ ...settings });

    }
    else {
      itemPath.forEach(element => {
        var itemIndex = oldItems.indexOf(element);
        if (itemIndex !== -1) {
          oldItems.splice(itemIndex, 1);
          change = true;
        }
        change && appContext.updateAppContext({ ...settings });
      });

    }

  }


  function getTreeItems() {
 
    return appContext.getTreeItems();
  }
  function setTreeItems(data) {
    return;
    appContext.getTreeItems(data);
  }
  return { handleOpenPage1, handleDeletePage, handleOnCreateNewPage, getPageSubPages, LoadFirstTime, selectedPath, setSelectedPath, isBusy, setIsBusy, getTreeItems, setTreeItems, setTreeItemExpandedStatus, dialog3b };
}
