import { DecoratorNode } from "lexical";
import * as React from "react";
import { YouTubeComponent } from "./yoututbe_component";

// Convert HTML DOM to Image component
function convertYoutubeElement(domNode) {
  if (domNode instanceof HTMLIFrameElement) {
    console.log("YAY ..... doing HTMLIFrameElement ---> Youtube node");
    const { src } = domNode;
    const node = $createYouTubeNode({ src });
    return { node };
  }
  return null;
}

export class YouTubeNode extends DecoratorNode {
  __id;

  constructor(id, key) {
    super(key);
    this.__id = id;
  }

  static getType() {
    return "youtube";
  }

  static clone(node) {
    //TFIXME First change
    return new YouTubeNode(node.__id, node.getKey());
  }

  static importJSON(serializedNode) {
    const node = $createYouTubeNode({ videoID: serializedNode.videoID });
    //node.setFormat(serializedNode.format);
    return node;
  }

  exportJSON() {
    return {
      type: "youtube",
      version: 1,
      videoID: this.__id,
    };
  }

  getId() {
    return this.__id;
  }

  exportDOM() {
    const element = document.createElement("iframe");
    element.setAttribute("src", this.__id);
    //element.setAttribute("alt", this.__altText);
    // How about width ?? center location ?
    return { element };
  }
  static importDOM() {
    return {
      img: (node) => ({
        conversion: convertYoutubeElement,
        priority: 0,
      }),
    };
  }
  getTextContent(_includeInert, _includeDirectionless) {
    return `https://www.youtube.com/watch?v=${this.__id}`;
  }

  createDOM(config) {
    const span = document.createElement("span");
    const theme = config.theme;
    const className = theme.video;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }
  updateDOM() {
    return false;
  }
  decorate( ) {
    return <YouTubeComponent nodeKey={this.getKey()} videoID={this.__id} />;
  }

  // Imprtant otherwise you should not wrap Node in  paragraph
  // since you will not be able to hit new line
  isInline() {
    return true;
  }
}

export function $createYouTubeNode({ videoID, key }) {
  return new YouTubeNode(videoID, key);
}

export function $isYouTubeNode(node) {
  return node instanceof YouTubeNode;
}
