import React, { Fragment, useState } from "react";

export function ErrorsComponents({ errors }) {
    if (!errors || errors.length === 0) {
        return <></>;
    }

    return (
        <>
            <div className="div_errors shake-on-appear my-3">
                {errors && errors.length > 0 && (
                    <Fragment>
                        <span>Please check: </span>

                        <ul>
                            {errors.map((error, index) => {
                                return (
                                    <Fragment key={index}>
                                        <li>{error}</li>
                                    </Fragment>
                                );
                            })}
                        </ul>
                    </Fragment>
                )}
            </div>
        </>
    );
}
