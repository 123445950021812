import { useContext, useEffect, useRef, useState } from "react";
import { AppManager } from "../utilities/AppManager";
import { Page_dto } from "../utilities/Dtos";
import { AppContext } from "../utilities/storage";
import { TreeItem } from "./other/TreeItem";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { PulseLoader } from "react-spinners";
import { PageManager } from "../utilities/PageManger";

import { useLeftMenu } from "../hooks/leftMenuHook";
import { TreeItemDto } from "../utilities/DTOs/TreeItemDTO";
import { AppContextType } from "../utilities/storage";
import { useModalNoControls, useModalYesNoCancel } from "../utilities/component_helper";
import { getIconLink, handleKeyBoardVisibility, route_login, url_base_url_icons } from "../utilities/consts";
import { handleLogout } from "../utilities/helper2";
import { useHistory } from "react-router-dom";

export default function LeftMenu({ onChangeCallback }) {
  // console.count("R left menu");
  var history = useHistory();

  const [editor] = useLexicalComposerContext();
  /** @type AppContextType */
  const appContext = useContext(AppContext);
  var am = new AppManager(appContext);
  const pm = new PageManager(appContext);
  //const editor = appContext.EditorRef.current;
  const dlg_icons = useModalNoControls({});

  const pickedIcon = useRef(null);
  const searchBox = useRef(null);
  const [keyword, setKeyword] = useState("");
  const { handleOpenPage1, handleDeletePage, handleOnCreateNewPage, getPageSubPages, LoadFirstTime, selectedPath, setSelectedPath, isBusy, setIsBusy, getTreeItems, setTreeItems, dialog3b, setTreeItemExpandedStatus } = useLeftMenu({ editor: editor, am: am, pm1: pm });

  function onCreateNewTreeItem(parentPath) {

    function simulateAdd() {

      var newName = Math.random().toString(36).slice(2);
      var newItem = new TreeItemDto({ displayName: newName, itemPath: parentPath + "/" + newName });
      var tree = getTreeItems();
      tree.addChildTo(parentPath, newItem);
      var t = Object.assign(new TreeItemDto({ displayName: "ggg" }), tree);
      console.log("**** simulate add");
      t.print();
      console.log("**** simulate add end *** ");
      setTreeItems(t);

    }
    // simulateAdd();
    //   return;

    handleOnCreateNewPage(parentPath, (pageName) => {
      var itemPath = parentPath + "/" + pageName;
      if (pageName === null) {
        // no page name was provided (canceled)
        return;
      }
      CreateNewTreeItemCallback(pageName, itemPath)

    })

    function handleLogout() {
        
    }

    function CreateNewTreeItemCallback(pageName, itemPath) {
      var newItem = new TreeItemDto({ displayName: pageName, itemPath });

      var tree = getTreeItems();
      tree.addChildTo(parentPath, newItem);
      var t = Object.assign(new TreeItemDto({}), tree);
      setTreeItems(t);
    }

  }

  // handle delete menu item
  function onDeleteTreeItem(itemPath) {

    handleDeletePage(itemPath, () => {
      DeleteTreeItemCallback(itemPath)
    })


    function DeleteTreeItemCallback(itemPath) {
      var tree = getTreeItems();
      var result = tree.removeItem(itemPath);
      if (result === true) {
        var t = Object.assign(new TreeItemDto({}), tree);
        setTreeItems(t);
      }

    }

  }



  function onMoveCopyItem(itemPath, parentPath, order = -1, copy = false) {


    // handle page move/copy and callback then
    var tree = getTreeItems();
    var result = TreeItemDto.moveItemTo(tree, itemPath, parentPath, order, copy);
    if (result === true) {
      var t = Object.assign(new TreeItemDto({}), tree);
      setTreeItems(t);
    }
  }

  function handleUpdatePageIcon(page) {

    var icons = ["api",
      "armor",
      "bbook",
      "book",
      "c",
      "db",
      "go",
      "laravel",
      "linux",
      "needle",
      "network",
      "processor",
      "q",
      "qq",
      "r",
      "rtxt",
      "steps",
      "steps2",
      "design",
      "1c",
      "one",
      "unity",
      "txt"];

    var i = icons.map((item, index) => <img className="icon-preview" key={item} src={getIconLink(item)} alt={item} onClick={() => { pickedIcon.current = item; dlg_icons.onClose(); onPositiveClick(pickedIcon.current); }} />);
    var c = <div className="div-icons">
      {i}
    </div>;

    dlg_icons.showModal({
      title: "title", body: c, canDismiss: true, cancelCallback: () => { }
    });


    function onPositiveClick(icon) {
      if (!page || !icon)
        return;

      var am = new AppManager(appContext);

      if (icon) {
        am.api_updatePageIcon(page, icon, () => { }, () => alert("failed icon"));
        appContext.updateTreeItems(appContext);
      }
    }


  }

  function handleSearchChange(keyword) {
    if (!tree) return;

    if (!keyword || keyword.trim() === "") {
      console.log("Clear filter");
      tree.clearFilter(tree);
    }
    else {
      console.log("Apply filter " + keyword);
      tree.applyFilter(keyword);
    }
  }

   
  useEffect(() => {
    handleSearchChange(keyword);
  }, [keyword]);

  useEffect(() => {
    setSelectedPath((o) => am.getActivePage().path);
  }, [appContext]);

  useEffect(() => {
    // run once
    // getPageSubPages(new TreeItemDto({ displayName: "/", itemPath: "" }));
    var tree = appContext.getTreeItems(appContext);
    if (tree) {

      setTreeItems(tree);
      setIsBusy(false);
    }
    /** @type {Page_dto} */
    const activePage = am.getActivePage();
    if (activePage && activePage.name && activePage.path) {
      LoadFirstTime(activePage.name, activePage.path);
    }
  }, []);

  // console.info("Render the tree with ", selectedPath);
  // tree && tree.print();
  //TFIXME  This line cause an error

  var tree = getTreeItems();
 

  //console.log("treeitems", appContext.isTreeItemsReady);




  return (
    <div className="left-menu-parent">
      <div style={{ border: "1px solid red" }}>{selectedPath}</div>
      {/* <div style={{ border: "1px solid red" }}>{am.isCurrentPageNeedSave() ? "Need Save" : "Clean"}</div> */}
      <button onClick={() => { setIsBusy(!isBusy); }}> ... </button>
      <button onClick={() => { setIsBusy(true); setTreeItems(null); setIsBusy(false); }}> null </button>
      <button onClick={() => { appContext.updateTreeItems(appContext); }}> update </button>
      <button onClick={() => { handleLogout(false,() => { console.log("do logout success callback"); history.push(route_login); }) }}> Logout </button>
      <button onClick={() => { handleLogout(true, () => { console.log("do logout-all success callback"); history.push(route_login); }) }}> LogoutA </button>
      <button onClick={() => {
        var items = appContext.getTreeItems(appContext);
        if (items) {
          setTreeItems(items);
          setIsBusy(false);
        }

        //console.log(appContext.draggedItem);
        // getPageSubPages(new TreeItemDto({ displayName: "/", itemPath: "" }));
        var editableElements = document.querySelectorAll('[contenteditable="true"]');

        // Disable spell-check for each contenteditable element
        editableElements.forEach(function (element) {
          element.setAttribute('spellcheck', 'false');
        });
      }}>
        show
      </button>
      <div className="xyz">
        <dialog3b.modal />
      </div>
      <div className="xyz">
        <dlg_icons.modal />
      </div>
      <div id="div_search">
        <input type="text" ref={searchBox} maxLength={20} className="searchbox" value={keyword} onChange={(e) => { setKeyword(e.target.value); }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              handleSearchChange(keyword);
              handleKeyBoardVisibility(e);
            }
          }} ></input>
      </div>
      <div className="left-menu" id="leftMenu">

        {appContext.isTreeItemsReady !== true ? <PulseLoader size={7} speedMultiplier={2} color={"red"} /> :
          tree && tree.displayName !== null && <TreeItem key={"root element"} selectedPath={selectedPath} searchKeyword={keyword} data={tree} modal3={dialog3b} handleUpdatePageIcon={handleUpdatePageIcon} handleOpenPage={handleOpenPage1} handleNewTreeItem={onCreateNewTreeItem} handleDeleteTreeItem={onDeleteTreeItem} handleMoveItem={onMoveCopyItem} setTreeItemExpandedStatus={setTreeItemExpandedStatus} />}
      </div>
    </div>
  );
}
