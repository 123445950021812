import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ParagraphNode } from "lexical";
import "./asset/styles/app.css";
import "./asset/styles/editor.css";
import MainPage from "./pages/main";
import { AppProvider } from "./utilities/storage";

import { AutoLinkNode, LinkNode } from "@lexical/link";
import { HeadingNode } from "@lexical/rich-text";

// eslint-disable-next-line
// eslint-disable-next-line
// eslint-disable-next-line
// eslint-disable-next-line
import { ListItemNode, ListNode } from "@lexical/list";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { QuoteNode } from "@lexical/rich-text";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { theme } from "./components/editor/editor_theme";
import { BigNoteNode } from "./components/editor/plugins/BigNotePlugin";

// import { InlineImageNode } from "./components/editor/plugins/imagePluginx/inlineImage_node";
import { ImageNodeZ } from "./components/editor/plugins/ImagePlugin/image_node";
import { YouTubeNode } from "./components/editor/plugins/youtubePlugin/youtube_node";
import LoginPage from "./pages/loginPage";

// Global error handler
function onError(error) {
  console.error("Found Error", error);
}

function Logout() {
  return "Logout here";
}
function App() {
  const initialConfig = {
    namespace: "MyEditor",
    theme,
    onError,
    nodes: [HeadingNode, ListNode, ListItemNode, CodeNode, BigNoteNode, ParagraphNode, AutoLinkNode, LinkNode, CodeNode, CodeHighlightNode, HorizontalRuleNode, QuoteNode, YouTubeNode, ImageNodeZ],
  };

  var isLoggedIn = localStorage.getItem("loggedIn") === "true";
  isLoggedIn = true;
  // <StrictMode>

  return (
    <Router>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/logout">
          <Logout />
        </Route>

        <Route
          exact
          path="/"
          render={() => (
            <LexicalComposer initialConfig={initialConfig}>
              <AppProvider>{<MainPage />}</AppProvider>
            </LexicalComposer>
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
