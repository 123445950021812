import { BaseDocument, IDocument, MyDocumentType } from "../IDocument";
import { ComponentA, GalleryEditor } from "../../components/GalleryEditor/GalleryEditor";
import React from "react";
import { json } from "stream/consumers";



export class GalleryDocument extends BaseDocument implements IDocument {



    constructor(DocumentPath: string) {
        super(DocumentPath, MyDocumentType.gallery);
    }





    OpenDocument(data: any, meta: any, successCallback: (...args: any[]) => unknown, failCallback: (...args: any[]) => unknown): any {
        
        successCallback && successCallback();
        return  JSON.parse(data);
    }


    SaveDocument(fileName: string): boolean {
        throw new Error("Method not implemented.");
    }
    ExportDocument(fileName: string, format: string): boolean {
        throw new Error("Method not implemented.");
    }
    IsDocumentSaved(): boolean {
        throw new Error("Method not implemented.");
    }
    UpdateDocumentMeta(): boolean {
        throw new Error("Method not implemented.");
    }

    private static v1: number = 0;
};