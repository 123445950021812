import { useContext, useEffect, useRef, useState } from "react";
import { PulseLoader } from "react-spinners";
import { AppManager } from "../../utilities/AppManager";


import { enum_file_process } from "../../utilities/enums";
import { AppContext } from "../../utilities/storage";
import { useLeftMenu } from "../../hooks/leftMenuHook";
import { Editor } from "../editor/Editor";
import { TreeItemDto } from "../../utilities/DTOs/TreeItemDTO";
import { url_base_url, url_base_url_icons } from "../../utilities/consts";

//TODOC THIS IS HOW YOU DOCUMENT A FUNCTION WITH {}
/**
 * This function performs a specific action using parameters a and b.
 * @param {Object} options - The options object.
 * @param {function} options.handleDeletePage - Handle Delete Page
 * @param {function} options.handleNewTreeItem - Hanfle Create New Page
 * @param {function} options.handleOpenPage - Handle Open Page
 * @param {function} options.handleMoveItem - Handle Move/Copy Item
 * @param {boolean} options.isSelected - The numeric parameter 'b'.
 * @param {TreeItemDto} options.data - The numeric parameter 'b'.
 * @returns {void}
 */

////  *  @param {Array<TreeItemDto>} options.data - The numeric parameter 'b'.
export function TreeItem({ data, modal3, handleDeleteTreeItem, handleNewTreeItem, handleOpenPage, handleMoveItem, selectedPath, setTreeItemExpandedStatus, handleUpdatePageIcon, searchKeyword }) {
  //console.log("R TreeItem with ", selectedPath);
  const appContext = useContext(AppContext);
  //const [subTreeItems, setSubItems] = useState(null);
  const [m1, setM1] = useState(modal3);

  // const [/** @type {TreeItemDto} */ childsData, setChildsData] = useState(data);
  const [dragUpdate, setDragUpdate] = useState(0);
  const { setDraggedItem } = appContext;
  const [isBusy, setIsBusy] = useState(false);
  const chifronSpan = useRef(null);
  const detailsItem = useRef(null);
  const treeItemOpen = useRef(false);
  const separatorItem = useRef(null);

  // Drag Related
  const treeItem = useRef(null);


  function PrepareSubTreeItems(inputData) {

    var subTreeItems =
      inputData &&
      inputData.getChildrens() &&
      inputData.getChildrens().map((item, index) => {
        if (item.path === "/") return <>.,.</>;
        return <TreeItem setDraggedItem={setDraggedItem} searchKeyword={searchKeyword} data={item} selectedPath={selectedPath} key={data.getPath() + index} modal3={m1} handleUpdatePageIcon={handleUpdatePageIcon} handleOpenPage={handleOpenPage} handleNewTreeItem={handleNewTreeItem} handleMoveItem={handleMoveItem} handleDeleteTreeItem={handleDeleteTreeItem} setTreeItemExpandedStatus={setTreeItemExpandedStatus} />;
      });
    return subTreeItems;
  }

  const subTreeItems = PrepareSubTreeItems(data);


  function isActiveSelectedItem() {
    return (data.path === selectedPath);
  }

  function isItemExpanded() {
    var r = isActiveSelectedItem() || appContext.getSettings().expandedItems.indexOf(data.path) >= 0;
    //console.info("is expanded: ", data.path, "is ", r)
    return r;
  }

  useEffect(() => {

    if (isActiveSelectedItem()) {
      var r = TreeItemDto.getItemParents(data, data.getPath());
      setTreeItemExpandedStatus(r, true);
    }

    if (data) {
      treeItemOpen.current = isItemExpanded();
    }

  }, []);

  useEffect(() => {
    //console.info("*2 in tree item " + data.getPath());

    if (data) {
      treeItemOpen.current = isItemExpanded();;
    }

    // eslint-disable-next-line
  }, [data, dragUpdate]);

  useEffect(() => {
    //console.info("*3 in tree item " + data.getPath());

    treeItemOpen.current = isItemExpanded();;
    // eslint-disable-next-line
  }, [selectedPath]);


  function dragStart(event) {
    event.stopPropagation();
    const pageManager = new AppManager(appContext);
    if (pageManager.isCurrentPageNeedSave()) {
      alert("Please save the page first");
      return;
    }

    console.log("start drag of", event.target);
    const isCopy = event.ctrlKey || event.altKey;

    const draggedItem = event.target;
    draggedItem.classList.add("dragging");
    setDraggedItem({ element: draggedItem, treeItemDto: data, react: this, process: isCopy ? enum_file_process.copy : enum_file_process.move });
    event.dataTransfer.setData("text", "dragging");

    var dragImageb64 = "https://img.icons8.com/fluency-systems-regular/32/file.png";

    const dragImage = new Image();
    dragImage.src = dragImageb64; // Replace with the path to a transparent image
    event.dataTransfer.setDragImage(dragImage, 0, 0);
    event.dataTransfer.effectAllowed = isCopy ? 'copy' : 'move';
    // event.dataTransfer.dropEffect = 'move';
    // Control the opacity of the drag image
    dragImage.style.opacity = 0.2;

    return true;
  }


  function drop_separator(event) {
    //drop for separator
    event.stopPropagation();
    event.preventDefault();
    var dropTarget = event.target;

    var dragged = appContext.draggedItem;
    if (!dragged) {
      console.log("drag item is null", dragged);
      return;
    }

    const draggedItem = dragged.element;
    const draggedItemDto = dragged.treeItemDto;
    const draggedItemPath = draggedItemDto.getPath();
    const process = dragged.process;
    const targetItemPath = data.getParent().getPath();

    console.log("drop dragged item", draggedItemPath, " on ", targetItemPath);

    if (draggedItemPath === targetItemPath) {
      console.warn("Self dragged is not allowed");
      return false;
    }

    if (dropTarget.classList.contains("tree-item-separator")) {
      dropTarget.classList.remove("drag-hover");
      //alert("move to: " + targetItemPath + "\nbefore : " + dropTarget.dataset.x + "\nor: " + separatorItem.current.dataset.x + "Code added later");

      var am = new AppManager(appContext);
      am.api_movePage(draggedItemPath, targetItemPath, process, separatorItem.current.dataset.o, onMoveSuccess, onMoveFail);
    }

    function onMoveSuccess(data) {
      draggedItemDto.canDisposeDragDone = false;

      draggedItemDto.canDisposeDragDone = true;
      setDraggedItem((old) => { return { ...old, draggedItemDto }; });
      setDragUpdate(Math.random() * 1000);

      handleMoveItem(draggedItemPath, targetItemPath, data.order, false);
    }

    function onMoveFail(error) {
      alert("failed");
    }


    return;
  }

  function drop(event) {
    event.stopPropagation();
    event.preventDefault();
    var dropTarget = event.target;

    var dragged = appContext.draggedItem;
    if (!dragged) {
      console.log("drag item is null", dragged);
      return;
    }

    const draggedItem = dragged.element;
    const draggedItemDto = dragged.treeItemDto;
    const draggedItemPath = draggedItemDto.getPath();
    const process = dragged.process;
    const targetItemPath = data.getPath();

    console.log("drop dragged item", draggedItemPath, " on ", targetItemPath);

    if (draggedItemPath === targetItemPath) {
      console.warn("Self dragged is not allowed");
      return false;
    }


    if (dropTarget.classList.contains("tree-item-text"))
      console.log("dragLeave for ", data.getPath());
    dropTarget.closest(".tree-item").classList.remove("drag-hover");



    var am = new AppManager(appContext);
    am.api_movePage(draggedItemPath, targetItemPath, process, -1/***separatorItem.current.data.x**/, onMoveSuccess, onMoveFail);


    function onMoveSuccess() {
      draggedItemDto.canDisposeDragDone = false;

      draggedItemDto.canDisposeDragDone = true;
      setDraggedItem((old) => { return { ...old, draggedItemDto }; });
      setDragUpdate(Math.random() * 1000);
      handleMoveItem(draggedItemPath, targetItemPath);
    }

    function onMoveFail(error) {
      alert("failed");
    }


    return;
    // TFIXME IF one day we implement indexing
    // const target = event.target;
    // if (target.classList.contains("tree-item-text")) {
    //   const targetRect = target.getBoundingClientRect();
    //   const draggedRect = draggedItem.getBoundingClientRect();

    //   if (event.clientY > draggedRect.top + draggedRect.height / 2) {
    //     //target.parentNode.insertBefore(draggedItem, target.nextSibling);
    //   } else {
    //     // target.parentNode.insertBefore(draggedItem, target);
    //   }
    // }
  }

  let dragEnter = function (event) {
    const dragTarget = event.target;
    event.preventDefault();
    event.stopPropagation();

    if (dragTarget.classList.contains('tree-item-separator')) {
      console.log("dragEnter for separaor", data.getPath());
      dragTarget.classList.add("drag-hover");
    }
    else if (dragTarget.classList.contains("tree-item-text")) {
      console.log("dragEnter for ", data.getPath());
      dragTarget.closest(".tree-item").classList.add("drag-hover");
    }
  };

  let dragLeave = function (event) {
    const dragTarget = event.target;
    event.preventDefault();
    event.stopPropagation();
    if (dragTarget.classList.contains('tree-item-separator')) {
      console.log("dragLeave for separaor", data.getPath());
      dragTarget.classList.remove("drag-hover");
    }
    else if (dragTarget.classList.contains("tree-item-text")) {
      console.log("dragLeave for ", data.getPath());
      dragTarget.closest(".tree-item").classList.remove("drag-hover");
      //target.closest(".tree-item").classList.remove("forbidden-drop");
    }
  };

  let dragOver = function (event) {
    const dragTarget = event.target;
    //console.log("drag over", target.classList);



    // if (dragTarget.classList.contains('tree-item-separator')) {
    //   console.error("no self drop");
    //   dragTarget.classList.add("forbidden-drop");
    // }
    // else 
    if (appContext.draggedItem && appContext.draggedItem.treeItemDto.getPath() === data.getPath()) {
      console.error("no self drop");
      dragTarget.closest(".tree-item").classList.add("forbidden-drop");
    } else {
      //  target.closest(".tree-item").classList.remove("forbidden-drop");
    }
    event.preventDefault();
  };

  let dragEnd = function (event) {
    event.preventDefault();
    event.stopPropagation();
    const target = event.target;
    console.log("drag end", target.classList);
    if (appContext.draggedItem) {

      target.classList.remove("forbidden-drop");
      target.classList.remove("dragging");
      const r = appContext.draggedItem.treeItemDto;
      if (r.canDisposeDragDone === true) {

        //        setChildsData(null);
        setDragUpdate(Math.random() * 1000);
      }
    }
  };

  useEffect(() => {
    //console.log("dragged item set to:", appContext.draggedItem);
    if (!data) return;
    //if (appContext.draggedItem && appContext.draggedItem.treeItemDto.getPath() === childsData.getPath())
    if (data.displayName === "2342") {
      console.log("dragged item set to:", appContext.draggedItem, " for 2342");
    }
    if (treeItem.current) {
      if (data.displayName === "2342") {
        console.log("Setup drags", appContext.draggedItem, " for 2342");
      }
      treeItem.current.addEventListener("dragstart", dragStart); // source
      treeItem.current.addEventListener("dragend", dragEnd); // source

      treeItem.current.addEventListener("dragover", dragOver); // targets
      treeItem.current.addEventListener("dragenter", dragEnter);// targets
      treeItem.current.addEventListener("dragleave", dragLeave);// targets

      treeItem.current.addEventListener("drop", drop); // target
      if (separatorItem !== null)
        separatorItem.current.addEventListener("drop", drop_separator); // target
    }

    return () => {

      if (treeItem.current) {
        if (data.displayName === "2342") {
          console.warn("delete drags", appContext.draggedItem, " for 2342");
        }
        if (separatorItem !== null)
          separatorItem.current.removeEventListener("drop", drop_separator);

        treeItem.current.removeEventListener("drop", drop);
        treeItem.current.removeEventListener("dragover", dragOver);
        treeItem.current.removeEventListener("dragstart", dragStart);
        treeItem.current.removeEventListener("dragenter", dragEnter);
        treeItem.current.removeEventListener("dragleave", dragLeave);
        treeItem.current.removeEventListener("dragend", dragEnd);
      }
    };
  }, [appContext.draggedItem, dragUpdate]);





  function DeletePageCallback(treeItem) {
    var parent = data.getParent();
    parent.removeChild(treeItem);

    //setChildsData(null);
  }



  /** @type TreeItemDto */
  if (!data || !data.displayName) return <>.,,.</>;

  if (!data) return "...,,...";

  const buttons = (
    <>
      <div className="tree-item-buttons">
        <button
          className="btn-add"
          onClick={async (e) => {
            e.stopPropagation();
            const pagePath = data.getPath();
            await handleDeleteTreeItem(pagePath, () => DeletePageCallback(data));
          }}>
          -
        </button>
        <button
          className="btn-add"
          onClick={async (e) => {
            e.stopPropagation();
            console.info("add page is clicked");
            const pagePath = data.getPath();
            await handleNewTreeItem(pagePath);
          }}>
          +
        </button>
      </div>
    </>
  );

  function handleOnPageChange(e) {
    e.preventDefault();
    e.stopPropagation();
    const newPagePath = data.getPath();
    const newPageName = data.getName();
    setIsBusy(true);
    handleOpenPage && handleOpenPage(newPagePath, newPageName, () => {
      setIsBusy(false);
    }, null);
  }

  function OnExpandCollapseIconClick(target) {
    const details = target.closest("details");
    if (details) {
      details.open = !details.open;
      if (details.open) chifronSpan.current.style.transform = "rotate(90deg)";
      else chifronSpan.current.style.transform = "rotate(0deg)";

      setTreeItemExpandedStatus(data.getPath(), details.open);
    }
  }


  if (treeItemOpen.current === true) {
    //console.log(data.path, " is open", treeItemOpen.current);
  }

  var needSave = false;
  var needSaveClass = "";
  if (isActiveSelectedItem()) {
    const pageManager = new AppManager(appContext);
    if (pageManager.isCurrentPageNeedSave()) {
      needSave = true;
      needSaveClass = "need-save";
    }
  }

  if (detailsItem.current && chifronSpan.current) {

    if (detailsItem.current.open)
      chifronSpan.current.style.transform = "rotate(90deg)";
    else
      chifronSpan.current.style.transform = "rotate(0deg)";
  }
  //<span style={{fontSize:"12px", color:"grey"}}>{data.path}</span>
  var renderElement = "-";
  var icons = '';//'🔴⚫🔵🟠🟡🟢🟣🟤⭕';

  icons = Array.from(icons);
  var debugOrder = "";// (<span style={{ backgroundClip: "lightGrey", fontSize: "8px" }}>({data.order})</span>);

  if (subTreeItems && subTreeItems.length > 0) {


    var shouldRender = data.visible;


    if (data.displayName === "/") {
      // Don't render root element / it is ugly
      renderElement = <>{subTreeItems}</>;
    } else {



      if (shouldRender === false) {
        renderElement = "";
      }
      else {
        renderElement = (
          <>
            <div className="tree-item-separator" draggable="false" ref={separatorItem} data-x={data.displayName} data-o={data.order} ></div>
            <div className={"tree-item "} draggable="true" ref={treeItem}>
              <details className="tree-folder" open={treeItemOpen.current === true ? true : false} onClick={(e) => { e.preventDefault(); }} ref={detailsItem}>
                <summary className={selectedPath === data.getPath() ? "active-tree-item" : ""}>
                  <span className="tree-name-and-buttons">
                    <button className="btn-expand" ref={chifronSpan} onClick={(e) => { OnExpandCollapseIconClick(e.target); }} >
                      <img className="ch" alt="123" src={url_base_url_icons + "/3.png"} src1="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAgElEQVR4nO3UsQ3CMBBA0bdBGIEOJkBMALtACRMxQcYApolLKiMkoECJaHyRLPl3rp4s35lWre2xjgTOyEjYRgDHN5AjocsPkjGUhjpcR6DUoH8tcJ94o42CLfEYgfpqbtLhFjnOU2M8RAOp5J7MsvGHOf6uV6do4NMOq++pVX1P3nlQZdlb7fIAAAAASUVORK5CYII="></img>
                    </button>
                    <span className="tree-item-icon" style={{ display: "none" }}>{icons[Math.floor(Math.random() * icons.length)]}</span>
                    <img src={data.icon} alt="" className="tree-item-icon" onClick={() => handleUpdatePageIcon(data.getPath())}  ></img>
                    <span className={"" + needSaveClass + " tree-item-text"} onClick={async (e) => { handleOnPageChange(e); }} ref={treeItem}>
                      {debugOrder}  {data.displayName}
                      {isBusy === true ? <PulseLoader style={{ display: "inline" }} size={3} speedMultiplier={2} color={"red"} /> : ""}
                    </span>
                    {buttons}
                  </span>
                </summary>
                {subTreeItems}
              </details>
            </div>
          </>
        );
      }
    }
  } else {

    shouldRender = data.visible;
    if (shouldRender === false) {
      renderElement = "";
    }
    else {
      renderElement = (
        <>
          <div className="tree-item-separator" draggable="false" ref={separatorItem} data-x={data.displayName} data-o={data.order}></div>
          <div className={"tree-item "} draggable="true" ref={treeItem}>
            <div className={"tree-item-single " + (selectedPath === data.getPath() ? "active-tree-item" : "")}>
              <button className="btn-expand" ref={chifronSpan} onClick={(e) => { OnExpandCollapseIconClick(e.target); }} style={{ visibility: "hidden" }} >
                <img className="ch" alt="123" src={url_base_url_icons + "/3.png"} src1="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAgElEQVR4nO3UsQ3CMBBA0bdBGIEOJkBMALtACRMxQcYApolLKiMkoECJaHyRLPl3rp4s35lWre2xjgTOyEjYRgDHN5AjocsPkjGUhjpcR6DUoH8tcJ94o42CLfEYgfpqbtLhFjnOU2M8RAOp5J7MsvGHOf6uV6do4NMOq++pVX1P3nlQZdlb7fIAAAAASUVORK5CYII="></img>
              </button>
              <span className="tree-item-icon" style={{ display: "none" }}>{icons[Math.floor(Math.random() * icons.length)]}</span>
              <img src={data.icon} alt="" className="tree-item-icon" onClick={() => handleUpdatePageIcon(data.getPath())} ></img>
              <span className={"tree-item-text " + needSaveClass} onClick={async (e) => { handleOnPageChange(e); }}  >
                {debugOrder}  {data.displayName}
                {isBusy === true ? <PulseLoader style={{ display: "inline" }} size={3} speedMultiplier={2} color={"red"} /> : ""}
              </span>
              {buttons}
            </div>
          </div>
        </>
      );
    }
  }

  // console.info("** Render tree item ", data.getPath());
  // data && data.print();
  return <>{renderElement}</>;
}
