
// In case of errors
//goto node_modules\react-scripts\lib\react-app.d.ts
///<<reference types="react-scripts" />
//declare module '*.mp3'; // '*.wav' if you're using wav format
//import clickSound1 from "./../assets/audio/click.mp3";

  export const time_invalidate_vendor_cahce = 2;
export const max_addresses_per_client = 8; // this also nee to be in sync with api


export const app_version = 100;
export const header_platform = "webftc";
export const default_app_language = 'en'
 
export const phone_number_support = "60122731182";
 

export const url_host = "https://kb.access.ly";
//export const url_api = url_host + "/index.php/api";
export const url_api = url_host + "/api";
export const url_api_users = url_api + "/users";
export const url_base_url_icons = url_host + "/assets/icons";


export const url_user_login = url_api_users + "/login";
export const url_user_logout = url_api_users + "/logout";
export const url_user_logout_all = url_api_users + "/logout-all";

export const url_get_files_list = url_api_users+ "/getFilesList";

export const url_new_file = url_api_users+ "/newFile";
export const url_save_file = url_api_users+ "/saveFile";
export const url_move_file = url_api_users+ "/moveFile";
export const url_update_icon = url_api_users+ "/updatePageIcon";
export const url_get_file_data = url_api_users+ "/getFile";
export const url_delete_file = url_api_users+ "/deleteFile";
export const url_get_icons = url_api+ "/icons";



//Routes
export const route_main = "/main";
export const route_login = "/login";
export const route_workspaces = "/workspaces";
export const route_logout = "/logout";


// Settings
export const setting_key_is_user_logged = "isLogged";
export const setting_key_bearer_token = "btoken";
export const setting_key_language = "lng";

export const setting_key_token = "token"; // to be used with Cookies jwt



// default language
export const language_en = "en";

 

 
 

export const route_vendors = "/";
export const route_favorite_vendors = "/favorite";
 

export function playClickSound() {
 // const sound = new Audio(clickSound1);
 // sound.play();
}
export function getIconLink(icon) {
  return url_base_url_icons + "/"+icon + ".ico";
}


export function handleKeyBoardVisibility(e) {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  e.preventDefault();
  if (isMobile) e.target.blur();
}