import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection";
import { mergeRegister } from "@lexical/utils";
import { $createNodeSelection, $createParagraphNode, $createTextNode, $getNodeByKey, $getSelection, $isNodeSelection, $setSelection, CLICK_COMMAND, COMMAND_PRIORITY_LOW, KEY_BACKSPACE_COMMAND, KEY_DELETE_COMMAND, KEY_ENTER_COMMAND, PASTE_COMMAND } from "lexical";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { $isImageNodeZ } from "./image_node";
import { INSERT_IMAGE_COMMANDZ } from "./image_plugin";

export function ImageZComponent({ src, altText, width, height, maxWidth, borderStyle, changeBorder, nodeKey }) {
  const editor = useLexicalComposerContext()[0];
  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey);
  // eslint-disable-next-line
  const [isEditable, setIsEditable] = useState(true);

  const imageRef = React.useRef(null);
  //const [selection, setSelection] = useState(false);

  const b1 = useRef(null);
  const b2 = useRef(null);

  function handleBorderChange(style) {
    editor.update(() => {
      changeBorder(style);
    });
  }
  const onDelete = useCallback(
    function onDelete(event) {
      if (isSelected && $isNodeSelection($getSelection())) {
        var node = $getNodeByKey(nodeKey);
        if ($isImageNodeZ(node)) {
          event.preventDefault();
          console.warn("delete node", nodeKey);
          var sibling = node.getPreviousSibling();
          node.remove();
          clearSelection();
          setSelected(false);
          editor.update(() => {
            // sselect previous element if any
            if (sibling !== null) {
              const nodeSelection = $createNodeSelection();
              nodeSelection.add(sibling.getKey());
              $setSelection(nodeSelection);
            }
          });

          return true;
        }
      }
      return false;
    },
    // eslint-disable-next-line
    [nodeKey, isSelected, setSelected]
  );

  const onEnter = useCallback(
    function onEnter(event) {
      if (isSelected && $isNodeSelection($getSelection())) {
        var node = $getNodeByKey(nodeKey);
        if ($isImageNodeZ(node)) {
          event.preventDefault();
          var sibling = node.getNextSibling();
          clearSelection();
          setSelected(false);
          editor.update(() => {
            // sselect previous element if any
            if (sibling !== null) {
              const nodeSelection = $createNodeSelection();
              nodeSelection.add(sibling.getKey());
              $setSelection(nodeSelection);
            } else {
              var p = $createParagraphNode();
              p.append($createTextNode(""));
              node.getParent().insertAfter(p);
              p.selectStart();
            }
          });

          return false;
        }
      }
      return false;
    },
    // eslint-disable-next-line
    [nodeKey, isSelected, setSelected]
  );

  const onPaste = useCallback(
    (event) => {
      console.log("On paste");

      let processed = false;

      if (!event || !event.clipboardData) {
        return false;
      }

      const items = (event.clipboardData || event.originalEvent.clipboardData).items;

      function x(base64String) {
        console.log("xxxx");
        editor.dispatchCommand(INSERT_IMAGE_COMMANDZ, { src: base64String });
      }

      for (const item of items) {
        if (item.type.indexOf("image") !== -1) {
          const file = item.getAsFile();
          const reader = new FileReader();

          reader.onload = (event) => {
            const base64String = event.target.result;
            x(base64String);
          };

          reader.readAsDataURL(file);
          // const blob = item.getAsFile();
          // const imageUrl = URL.createObjectURL(blob);
          processed = true;
        }
      }

      return processed;
    },
    // eslint-disable-next-line
    [nodeKey, isSelected, setSelected]
  );

  useEffect(() => {
    let isMounted = true;
    const unregister = mergeRegister(
      editor.registerEditableListener((status) => {
        console.warn("Editor status is:", status);
        setIsEditable(status);
      }),
      editor.registerCommand(
        CLICK_COMMAND,
        (payload) => {
          const event = payload;
          if (event.target === imageRef.current || event.target === b1.current || event.target === b2.current) {
            clearSelection();
            setSelected(true);
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),

      editor.registerCommand(KEY_DELETE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ENTER_COMMAND, onEnter, COMMAND_PRIORITY_LOW),
      editor.registerCommand(PASTE_COMMAND, onPaste, COMMAND_PRIORITY_LOW)
      // editor.registerCommand(KEY_ESCAPE_COMMAND, onEscape, COMMAND_PRIORITY_LOW)
    );
    //console.warn("render old image with key", nodeKey);

    return () => {
      isMounted = false;
      //console.log("use Effect destructor", isMounted);

      unregister();
    };
  }, [clearSelection, editor, isSelected, nodeKey, onDelete, onEnter, onPaste, setSelected]);

  return (
    <div className={(isEditable === true && (isSelected ? "f1" : "f2")) || ""}>
      {isEditable && isSelected === true && (
        <>
          <button ref={b1} onClick={() => handleBorderChange("4px dashed green")}>
            B1
          </button>
          <button ref={b2} onClick={() => handleBorderChange("4px solid pink")}>
            B2
          </button>
          <br />
        </>
      )}

      <img
        className=""
        src={src}
        alt={altText}
        ref={imageRef}
        style={{
          height,
          maxWidth,
          width,

          border: borderStyle,
        }}
      />
    </div>
  );
}
