import { DecoratorNode, createEditor } from "lexical";
import * as React from "react";
import { ImageZComponent } from "./image_component";

// Convert HTML DOM to Image component
function convertImageElement(domNode) {
  if (domNode instanceof HTMLImageElement) {
    console.log("doing HTMLImageElement ---> ImageNode");
    const { alt: altText, src } = domNode;
    const node = $createImageNodeZ({ altText, src });
    return { node };
  }
  return null;
}

// If use BlockDecorator mode can't press enter to go down
export class ImageNodeZ extends DecoratorNode {
  __src;
  __altText;
  __width;
  __height;
  __maxWidth;
  __showCaption;
  __caption;
  __captionsEnabled;
  __borderStyle;

  constructor(src, altText, maxWidth, width, height, showCaption, caption, captionsEnabled, borderStyle, key) {
    //
    super(key);
    this.__src = src;
    this.__altText = altText;
    this.__maxWidth = maxWidth;
    this.__width = width || "inherit";
    this.__height = height || "inherit";
    this.__showCaption = showCaption || false;
    this.__caption = caption || createEditor();
    this.__borderStyle = borderStyle || "1px solid orange";
    this.__captionsEnabled = captionsEnabled || captionsEnabled === undefined;
  }

  static getType() {
    return "image";
  }

  static clone(node) {
    return new ImageNodeZ(node.__src, node.__altText, node.__maxWidth, node.__width, node.__height, node.__showCaption, node.__caption, node.__captionsEnabled, node.__borderStyle, node.__key);
  }

  static importJSON(serializedNode) {
    const { altText, height, width, maxWidth, caption, src, showCaption, borderStyle } = serializedNode;
    const node = $createImageNodeZ({ altText, height, maxWidth, showCaption, src, width, borderStyle });

    const nestedEditor = node.__caption;
    const editorState = nestedEditor.parseEditorState(caption.editorState);
    // Load the caption as nested editor
    if (!editorState.isEmpty()) {
      nestedEditor.setEditorState(editorState);
    }
    return node;
  }
  
  exportJSON() {
    return {
      borderStyle: this.__borderStyle,
      altText: this.__altText,
      caption: this.__caption.toJSON(),
      height: this.__height === "inherit" ? 0 : this.__height,
      maxWidth: this.__maxWidth,
      showCaption: this.__showCaption,
      src: this.__src,
      type: "image",
      version: 1,
      width: this.__width === "inherit" ? 0 : this.__width,
    };
  }

  exportDOM() {
    const element = document.createElement("img");
    element.setAttribute("src", this.__src);
    element.setAttribute("alt", this.__altText);
    // How about width ?? center location ?
    return { element };
  }

  static importDOM() {
    return {
      img: (node) => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    };
  }

  createDOM(config) {
    const span = document.createElement("span");
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }
  updateDOM() {
    return false;
  }

  setWidthAndHeight(width = "inherit", height = "inherit") {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  setShowCaption(showCaption) {
    const writable = this.getWritable();
    writable.__showCaption = showCaption;
  }

  //   getSrc() {
  //     return this.__src;
  //   }

  //   getAltText() {
  //     return this.__altText;
  //   }
  setBorder(newBorder) {
    const writable = this.getWritable();
    writable.__borderStyle = newBorder;
  }

  decorate() {
    return <ImageZComponent   borderStyle={this.__borderStyle} changeBorder={(x) => this.setBorder(x)} src={this.__src} altText={this.__altText} width={this.__width} height={this.__height} maxWidth={this.__maxWidth} nodeKey={this.getKey()} showCaption={this.__showCaption} caption={this.__caption} captionsEnabled={this.__captionsEnabled} resizable={true} />;
  }
}

export function $createImageNodeZ({ altText, height, maxWidth = 500, captionsEnabled, src, width, showCaption, caption, borderStyle, key }) {
  return new ImageNodeZ(src, altText, maxWidth, width, height, showCaption, caption, captionsEnabled, borderStyle, key);
}

export function $isImageNodeZ(node) {
  return node instanceof ImageNodeZ;
}