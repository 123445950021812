import { LexicalEditor } from "lexical";
import { TextRichDocument } from "./DocumentHandlers/TextRichDocument";
import { BaseDocument, MyDocumentType } from "./IDocument";
import { enum_page_type } from "./enums";
import { GalleryDocument } from "./DocumentHandlers/GalleryHandler";

export class DocumentManager {

    public static GetDocumentHandler(DocumentType: string, DocumentPath: string, editor: LexicalEditor, appContext: any): BaseDocument {

        console.log("in GetDocumentHandler");
        if (DocumentType === MyDocumentType.text.toString() || DocumentType === enum_page_type.rich_text_document) {
            return new TextRichDocument(DocumentPath, editor, appContext);
        }
        else if (DocumentType === MyDocumentType.gallery.toString() || DocumentType === enum_page_type.image_gallery) {
            return new GalleryDocument(DocumentPath );
        }
        return new TextRichDocument(DocumentPath, editor, appContext);
    }

};
