import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

export function MyCustomAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();
  //console.log("in AutoFocusPlugin");
  useEffect(() => {
    editor.focus();
  }, [editor]);

  return null;
}
