import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils";
import { $createParagraphNode, $insertNodes, $isRootOrShadowRoot, COMMAND_PRIORITY_EDITOR, createCommand } from "lexical";
import { useEffect } from "react";
import { $createImageNodeZ, ImageNodeZ } from "./image_node";
export const INSERT_IMAGE_COMMANDZ = createCommand("INSERT_IMAGE_COMMAND");

export default function ImageZPlugin(captionsEnabled) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ImageNodeZ])) {
      throw new Error("ImagesPlugin: ImageNode not registered on editor");
    }

    return mergeRegister(
      // 1) Sample to convert word opo to an BigElement
      // editor.registerNodeTransform(ParagraphNode, (textNode) => {
      //   return;
      //   // This transform runs only once ( you must change what trigger the condition)
      //   if (textNode.getTextContent().indexOf("opo") >= 0) {
      //     var child = textNode.getFirstChild();

      //     child.setTextContent("new");

      //     const selection = $getSelection();
      //     if ($isRangeSelection(selection)) {
      //       console.log("is selected ..............");
      //       $setBlocksType(selection, $createBigNoteNode);
      //     }
      //   }
      // }),
      editor.registerCommand(
        INSERT_IMAGE_COMMANDZ,
        (payload) => {
          console.info("register image plugin command now");
          const imageNode = $createImageNodeZ(payload);
          $insertNodes([imageNode]);
          if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
            const node = $wrapNodeInElement(imageNode, $createParagraphNode);

            node.selectEnd();
            // $getRoot()
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [captionsEnabled, editor]);

  return null;
}
