import React from "react";
import { PulseLoader } from "react-spinners";
import { playClickSound } from "../../utilities/consts";
 

export default function Button({className="", isBusy=false, onClick, children="", ...props}) {
    return (
        <button className={className + " gbutton "} onClick={ ()=>{playClickSound(); onClick()}} disabled={(props && props.disabled) || isBusy}>
            <span className="d-flex justify-content-center align-items-center py-0">
                {isBusy && <PulseLoader color="var(--back-color-light-light)" size={8} speedMultiplier={2} cssOverride={{ marginRight: "4px" }} loading />}
                <span> {children}</span>
            </span>
        </button>
    );
}
 